import {User} from "src/models/User";
import React, {useContext, useState} from "react";
import {UserContext} from "src/components/Authentication/Authentication";
import CreateOrUpdateQueuesBuilder from "src/builders/CreateOrUpdateQueuesBuilder";
import {ModalContent, WithModal} from "@amzn/stencil-react-components/modal";
import {Button, ButtonVariant} from "@amzn/stencil-react-components/button";
import {FormWrapper, TextArea} from "@amzn/stencil-react-components/form";
import {Col} from "@amzn/stencil-react-components/layout";
import {Label} from "@amzn/stencil-react-components/text";
import {Queue} from "src/models/Queue";
import {QueueUserOperationType} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import GetUserDetailsBuilder from "src/builders/GetUserDetails";
import {MessageBanner, MessageBannerType} from "@amzn/stencil-react-components/message-banner";
import {INFO_MSG_BANNER_AUTO_DISMISS_TIME, SUCCESS_MSG_BANNER_AUTO_DISMISS_TIME} from "src/constants/DisplayConstants";

interface AddUsersToQueueModalProps {
    users: User[];
    queue: Queue;
    domainId: string;
    onTableReload: Function
    setMessageBannerDetails: Function,
    flipBannerVisibility: Function

}
const AddUsersToQueueModal = ({users, queue, domainId, onTableReload, setMessageBannerDetails, flipBannerVisibility}: AddUsersToQueueModalProps) => {
    const userContext = useContext(UserContext).authenticatedUser;
    const [employeeIds, setEmployeeIds] = useState('');

    const addUsers = async (close) => {
        try {
            let queueDetails = {...queue};
            const userIds: string[] = [];
            const usersNotFound: string[] = [];
            const usersSuccessfullyAdded: string[] = [];
            const usersNotAdded: string[] = [];
            const listOfEmployeeIds = employeeIds.split(',').map(employeeId => employeeId.trim())
            for (const employeeId of listOfEmployeeIds){
                try {
                    const response = await GetUserDetailsBuilder.getUserDetails(domainId, employeeId, undefined);
                    userIds.push(response.user.userId);
                } catch (error) {
                    usersNotFound.push(employeeId);
                }
            }
            for (const userId of userIds) {
                try {
                    queueDetails['userOperation'] = { type: QueueUserOperationType.ADD, userIds: [userId]}
                    const editQueueResponse = await CreateOrUpdateQueuesBuilder.editQueue(domainId, userContext.alias, queueDetails);
                    usersSuccessfullyAdded.push(userId);
                } catch (error) {
                    usersNotAdded.push(userId);
                }
            }
            onTableReload(domainId);
            setMessageBannerDetails({
                messageBannerText: `Users added successfully: ${usersSuccessfullyAdded}. ` +
                `Employee Ids not found in domain: ${usersNotFound}. ` +
                `Users not added: ${usersNotAdded}. `,
                messageBannerType: MessageBannerType.Informational,
                messageBannerAutoDismiss: INFO_MSG_BANNER_AUTO_DISMISS_TIME
            });
            flipBannerVisibility();
        } catch (error) {
            console.error(error.message);
        }
        close();
        setEmployeeIds('');
    };

    const renderModal = ({close}) => (
        <ModalContent
            maxWidth="90vw"
            titleText={`Add Users`}
            buttons={[
                <Button onClick={() => addUsers(close)} variant={ButtonVariant.Primary}>
                    Add Users to Queue
                </Button>,
            ]}
        >
            <Col height={'30vh'}>
                <FormWrapper width={600}>
                    <Label htmlFor="some-textarea-id">
                        Enter comma-separated list of employeeIds
                    </Label>
                    <TextArea
                        id="some-textarea-id"
                        resize="vertical"
                        onChange={(event) => {
                            setEmployeeIds(event.target.value);
                        }}
                    />
                </FormWrapper>
            </Col>
        </ModalContent>
    );
    return (
        <WithModal renderModal={renderModal} isScrollable={false}>
            {({ open }) => (
                <Button
                    variant={ButtonVariant.Tertiary}
                    onClick={open}
                >
                    Add Users
                </Button>
            )}
        </WithModal>
    );
}

export default AddUsersToQueueModal