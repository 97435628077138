import GetUserDetails from "src/builders/GetUserDetails";
import {DEFAULT_FILE_NAME, ERROR_CODE_4XX} from "src/constants/DisplayConstants";
import {browserHistory} from "src/AppRoutes";
import {UrlUtils} from "src/utils/UrlUtils";
import {PAGE_PATHS} from "src/constants/Urls";
import {ERROR_CODE} from "src/constants/enums";
import {FileUtils} from "src/utils/FileUtils";
import Papa from "papaparse";
import GetQueuesBuilder from "src/builders/GetQueuesBuilder";
import {Queue} from "src/models/Queue";
import GetUserGroupsBuilder from "src/builders/GetUserGroupsBuilder";
import userGroups from "src/pages/UserGroups/UserGroups";

export interface ExportCSVProps {
    fileName? : string,
    fileData? : any,
    csvExportEnabled?: boolean,
}

export const getUserByEmployeeIDInQueue = async (
    employeeId,
    queueId,
    queueName,
    domainId,
    userList
) => {
    try {
        userList.initiateAsyncState();
        const getUserDetailsResponse = await GetUserDetails.getUserDetails(domainId, employeeId);
        const user = getUserDetailsResponse.user;
        const getQueuesResponse = await GetQueuesBuilder.getQueues(domainId, undefined, queueName, user.userId);
        const userMatchingQueues = getQueuesResponse.queues.filter((queue, index, array) => {
            return queue.queueId == queueId;
        })
        if (userMatchingQueues.length > 0) {
            userList.setAsyncSuccess([user]);
        } else {
            userList.setAsyncSuccess([]);
        }
    } catch (error) {
        userList.setAsyncFailure(error);
    }
}

export const getUserByEmployeeIDInUserGroup = async (
    employeeId,
    userGroupId,
    domainId,
    userList
) => {
    try {
        userList.initiateAsyncState();
        const getUserDetailsResponse = await GetUserDetails.getUserDetails(domainId, employeeId);
        const user = getUserDetailsResponse.user;
        const getGroupsResponse = await GetUserGroupsBuilder.getUserGroups(domainId, user.userId);
        const userMatchingGroups = getGroupsResponse.userGroups.filter((group, index, array) => {
            return group.userGroupId == userGroupId;
        })
        if (userMatchingGroups.length > 0) {
            userList.setAsyncSuccess([user]);
        } else {
            userList.setAsyncSuccess([]);
        }
    } catch (error) {
        userList.setAsyncFailure(error);
    }
}

export const getUsersForEmployeeIDInDomain = async (
    employeeId,
    domainId,
    userList
) => {
    try {
        userList.initiateAsyncState();
        const getUserDetailsResponse = await GetUserDetails.getUserDetails(domainId, employeeId);
        userList.setAsyncSuccess([getUserDetailsResponse.user]);
    } catch (error) {
        if (error.errorStatus != undefined && ERROR_CODE_4XX.test(error.errorStatus.toString())) {
            browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, error.errorStatus));
        } else {
            browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, ERROR_CODE.GENERIC_FAILURE));
        }
        userList.setAsyncFailure(error);
    }
};

const exportDataInCSV = ({fileName, fileData}) => {
    const data = Papa.unparse(fileData, {header: true});
    fileName = fileName ? fileName : DEFAULT_FILE_NAME
    const fileType = "text/csv";
    FileUtils.downloadFile({ data, fileName, fileType});
}

export const CommonUtils = {getUsersForEmployeeIDInDomain, exportDataInCSV};