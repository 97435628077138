import {User} from "src/models/User";
import React, {useContext, useState} from "react";
import {UserContext} from "src/components/Authentication/Authentication";
import {ModalContent, WithModal} from "@amzn/stencil-react-components/modal";
import {Button, ButtonVariant} from "@amzn/stencil-react-components/button";
import {FormWrapper, TextArea} from "@amzn/stencil-react-components/form";
import {Col} from "@amzn/stencil-react-components/layout";
import {Label} from "@amzn/stencil-react-components/text";
import {UserGroupOperationType} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import {UserGroup} from "src/models/UserGroup";
import GetUserDetailsBuilder from "src/builders/GetUserDetails";
import {MessageBannerType} from "@amzn/stencil-react-components/message-banner";
import {GLOBAL, INFO_MSG_BANNER_AUTO_DISMISS_TIME} from "src/constants/DisplayConstants";
import CreateOrUpdateUserGroupsBuilder from "src/builders/CreateOrUpdateUserGroups";

interface AddUsersToUserGroupModalProps {
    users: User[];
    userGroup: UserGroup;
    domainId: string;
    onTableReload: Function;
    setMessageBannerDetails: Function;
    flipBannerVisibility: Function;
}
const AddUsersToUserGroupModal = ({users, userGroup, domainId, onTableReload, setMessageBannerDetails, flipBannerVisibility}: AddUsersToUserGroupModalProps) => {
    const userContext = useContext(UserContext).authenticatedUser;
    const [employeeIds, setEmployeeIds] = useState('');

    const addUsers = async (close) => {
        try {
            let userGroupDetails = {...userGroup};
            const userIds: string[] = [];
            const usersNotFound: string[] = [];
            const usersSuccessfullyAdded: string[] = [];
            const usersNotAdded: string[] = [];
            const listOfEmployeeIds = employeeIds.split(',').map(employeeId => employeeId.trim()).filter(employeeId => employeeId !== "");
            if (domainId == GLOBAL) {
                for (const employeeId of listOfEmployeeIds) {
                    try {
                        userGroupDetails['userOperation'] = {type: UserGroupOperationType.ADD, userIds: undefined,employeeIds: [employeeId]}
                        const editQueueResponse = await CreateOrUpdateUserGroupsBuilder.editUserGroup(domainId, userContext.alias, userGroupDetails);
                        usersSuccessfullyAdded.push(employeeId);
                    } catch (error) {
                        usersNotAdded.push(employeeId);
                    }
                }
            } else {
                for (const employeeId of listOfEmployeeIds) {
                    try {
                        const response = await GetUserDetailsBuilder.getUserDetails(domainId, employeeId, undefined);
                        userIds.push(response.user.userId);
                    } catch (error) {
                        usersNotFound.push(employeeId);
                    }
                }
                for (const userId of userIds) {
                    try {
                        userGroupDetails['userOperation'] = {type: UserGroupOperationType.ADD, userIds: [userId], employeeIds: undefined}
                        const editQueueResponse = await CreateOrUpdateUserGroupsBuilder.editUserGroup(domainId, userContext.alias, userGroupDetails);
                        usersSuccessfullyAdded.push(userId);
                    } catch (error) {
                        usersNotAdded.push(userId);
                    }
                }
            }

            onTableReload(domainId);
            setMessageBannerDetails({
                messageBannerText: `Users added successfully: ${usersSuccessfullyAdded}. ` +
                    `Employee Ids not found in domain: ${usersNotFound}. ` +
                    `Users not added: ${usersNotAdded}. `,
                messageBannerType: MessageBannerType.Informational,
                messageBannerAutoDismiss: INFO_MSG_BANNER_AUTO_DISMISS_TIME
            });
            flipBannerVisibility();
        } catch (error) {
            console.error(error.message);
        }
        close();
        setEmployeeIds('');
    }
    const renderModal = ({close}) => (
        <ModalContent
            maxWidth="90vw"
            titleText={`Add Users`}
            buttons={[
                <Button onClick={() => addUsers(close)} variant={ButtonVariant.Primary}>
                    Add Users to User Group
                </Button>,
            ]}
        >
            <Col height={'30vh'}>
                <FormWrapper width={600}>
                    <Label htmlFor="some-textarea-id">
                        Enter comma-separated list of employeeIds
                    </Label>
                    <TextArea
                        id="some-textarea-id"
                        resize="vertical"
                        onChange={(event) => {
                            setEmployeeIds(event.target.value);
                        }}
                    />
                </FormWrapper>
            </Col>
        </ModalContent>
    );
    return (
        <WithModal renderModal={renderModal} isScrollable={false}>
            {({ open }) => (
                <Button
                    variant={ButtonVariant.Tertiary}
                    onClick={open}
                >
                    Add Users
                </Button>
            )}
        </WithModal>
    );
}

export default AddUsersToUserGroupModal