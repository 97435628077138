const fieldNameRegExp: RegExp = /^([a-z])([A-Za-z0-9]+)$/;
const upperCaseStringRegExp: RegExp = /^([A-Z]+)$/;
const generateStringHash = (stringValue: string): string => {
    return stringValue
        .split('')
        .map((v) => v.charCodeAt(0))
        .reduce((a, v) => (a + ((a << 7) + (a << 3))) ^ v)
        .toString(16);
};

const stringToBoolean = (stringValue: string): boolean => {
    return stringValue === "true";
}

const isValidFieldName = (stringValue: string): boolean => {
    // string is either all upperCase, or starts w/ lowerCase and can have numbers also but no special characters
    return fieldNameRegExp.test(stringValue) || upperCaseStringRegExp.test(stringValue)
}

export const StringUtils = {
    generateStringHash, stringToBoolean, isValidFieldName
}