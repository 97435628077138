import config from './CognitoAuthenticationConfig.json';

const hostDomain = window.location.host;
const stageConfig = config[hostDomain]

const oauth = {
    domain: stageConfig.USER_POOL_DOMAIN,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: window.location.protocol + '//' + window.location.host,
    responseType: 'code',
    options: {
        AdvancedSecurityDataCollectionFlag: false,
    },
};

/**
 * AWS Amplify config
 */
export const amplifyConfig = {
    Auth: {
        region: stageConfig.USER_POOL_REGION, // Amazon Cognito Region
        userPoolId: stageConfig.USER_POOL_ID, // Amazon Cognito User Pool ID
        userPoolWebClientId: stageConfig.USER_POOL_APP, // Amazon Cognito Web Client ID (26-char alphanumeric string)
        mandatorySignIn: true, // Enforce user authentication prior to accessing AWS resources or not
        cookieStorage: {
            // Configuration for cookie storage
            domain: window.location.hostname, // Cookie domain (only required if cookieStorage is provided)
            path: '/', // Cookie path
            expires: 365, // Cookie expiration in days
            secure: stageConfig.COOKIES_SECURE_FLAG, // Cookie secure flag to be false for localhost
            sameSite: stageConfig.COOKIES_SAME_SITE_PROPERTY, // sameSite flag to be none or strict to allow set Midway to set auth cookies in Chrome and Safari browsers
        },
        oauth: oauth,
    },
};

/**
 * The url of the Cognito Hosted UI to be redirected here if user unauthenticated
 */
export const cognitoUrl = `https://${oauth.domain}/authorize?client_id=${amplifyConfig.Auth.userPoolWebClientId}&response_type=${oauth.responseType}&redirect_uri=${oauth.redirectSignIn}`;