import React, {useState} from 'react';

import {Flex} from "@amzn/stencil-react-components/layout";
import {IconCross, IconDownload, IconQuestionCircle, IconTick} from "@amzn/stencil-react-components/icons";
import {StencilProvider} from "@amzn/stencil-react-components/context";
import {ENTITY_TYPE} from "src/constants/enums";
import {Button, ButtonSize, ButtonVariant} from "@amzn/stencil-react-components/button";
import {FileUpload} from "@amzn/stencil-react-components/file-upload";
import {Text} from "@amzn/stencil-react-components/text";
import {withTooltip} from '@amzn/stencil-react-components/tooltip';
import Papa from "papaparse";
import {BulkFileUploadExpectedHeaders, TableRequiredFields} from "src/configurations/InputElementConfigs";
import {FileUtils} from "src/utils/FileUtils";
import EntityTable from "src/components/EntityTable";
import {
    DEFAULT_TEMPLATE_FILE_NAME,
    BULK_PAGE_TOOLTIP_TEXT,
} from "src/constants/DisplayConstants";

export enum BulkActionType {
    CREATE,
    UPDATE,
    CREATE_OR_UPDATE,
    REMOVE,
    ADD_USERS_TO_QUEUE,
    REMOVE_USERS_FROM_QUEUE,
    ADD_USERS_TO_GROUP,
    REMOVE_USERS_FROM_GROUP,
    DEFAULT
}

interface BulkActionPageProps {
    bulkActionName: string;
    domainId: string;
    entityType: ENTITY_TYPE;
    templateFileName?: string;
    onClose?: () => void;
    onSave?: (records: any[], actionType: BulkActionType) => void;
    bulkActionType: BulkActionType;
    enableActionColumn?: boolean;
}

const downloadUploadFileTemplate = (entityType: ENTITY_TYPE, templateFileName: string | undefined) => {
    const headers = BulkFileUploadExpectedHeaders[entityType];
    const fileName = templateFileName?? DEFAULT_TEMPLATE_FILE_NAME;
    FileUtils.downloadCsvWithHeader({headers, fileName})
}

export const BulkActionPage = ({ onClose, onSave, bulkActionName, templateFileName, entityType, domainId, bulkActionType, enableActionColumn = true}: BulkActionPageProps) => {
    const requiredFields = [...TableRequiredFields[entityType]];
    const [tableEntries, setTableEntries] = useState([]);

    const parseObjectData = (results) => {
        const newRecordList: any = []
        results.data.forEach( (record) => {
            try {
                for (let field in record) {
                    record[field] = record[field].trim();
                    if (record[field] === '') {
                        delete record[field];
                    }
                }
                newRecordList.push(record);
            } catch (error) {
                console.error(error.message);
            }
        });
        setTableEntries(newRecordList);
    }

    const fileUploadHandler = (files) => {
        if(files.length > 0) {
            Papa.parse(files[0], {
                header: true,
                skipEmptyLines: true,
                complete: results => parseObjectData(results)
            });
        } else {
            setTableEntries([]);
        }
    };

    const IconWithTooltip = withTooltip()(IconQuestionCircle);

    return (
        <StencilProvider>
            <Flex flexDirection={'row'} justifyContent={'space-between'}>
                <Flex flexDirection={'row'} justifyContent={'flex-start'}  columnGap={'10px'} margin={'10px'}>
                    <Text textAlign = "center">
                        {bulkActionName}
                    </Text>
                </Flex>
                <Flex flexDirection={'row'} columnGap={'10px'} justifyContent={'flex-end'} alignItems={'center'} >
                    <IconWithTooltip
                        color="primary70"
                        tooltipText={BULK_PAGE_TOOLTIP_TEXT}
                    />
                    <Button icon={<IconDownload />}
                            variant={ButtonVariant.Primary}
                            size={ButtonSize.Small}
                            onClick={() => downloadUploadFileTemplate(entityType, templateFileName)}> Download sample csv file </Button>
                    <Button icon={<IconTick />}
                            size={ButtonSize.Small}
                            onClick={() => onSave? onSave(tableEntries, bulkActionType) : null}> Submit </Button>
                    <Button icon={<IconCross />}
                            isDestructive={true}
                            size={ButtonSize.Small}
                            onClick={() => onClose? onClose(): null}> Close </Button>

                </Flex>
            </Flex>

        <Flex flexDirection={'column'} margin={'10px'}>
            <FileUpload
                accept=".csv"
                maxFiles={1}
                onFileAttached={ (files) => fileUploadHandler(files)}
            />
        </Flex>
            {tableEntries.length > 0 ? <EntityTable data={tableEntries} domainId={domainId} requiredFields={requiredFields} enableActionColumn = {enableActionColumn}/>: null}
        </StencilProvider>
    );
};
