import { Flex} from "@amzn/stencil-react-components/layout";
import React, { ReactElement } from "react";
import {
    PageHeader,
    PageHeaderColorScheme,
} from "@amzn/stencil-react-components/page";
import SearchBar from "src/components/SearchBar";
import CreateModal from "src/components/CreateModal";
import {Button, ButtonVariant} from "@amzn/stencil-react-components/button";
import {IconRefresh, IconSize} from "@amzn/stencil-react-components/icons";
import {DropDownActions, DropdownActionsProps} from "src/components/DropdownActions";

interface TableHeaderProps {
    entityName: string;
    BulkActions?: ReactElement;
    dropdownActions?: DropdownActionsProps;
    CustomCreateModal?: ReactElement;
    onTableReload?: Function;
    searchByFilter?: Function;
}

const TableHeader = ({ entityName, CustomCreateModal, BulkActions, dropdownActions, onTableReload, searchByFilter }: TableHeaderProps) => {
    // will take the logic to add CustomModal in follow up CR
    let CreateModalStyle;
    if (CustomCreateModal) {
        CreateModalStyle = CustomCreateModal;
    } else {
        CreateModalStyle = <CreateModal />;
    }

    return (
        <Flex flexDirection={'row'} justifyContent={'space-between'}>
            <SearchBar entityName={entityName} searchByFilter={searchByFilter} />

            <PageHeader colorScheme={PageHeaderColorScheme.Inverted}>
                <nav
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    {CreateModalStyle}
                    {BulkActions}
                    {dropdownActions? <DropDownActions {...dropdownActions}/> : null}
                    <Button
                        icon={<IconRefresh title={'Reload'} size={IconSize.Small} />}
                        onClick={() => {
                            if (onTableReload) {
                                onTableReload();
                            }
                        }
                        }
                        variant={ButtonVariant.Tertiary}
                    />
                </nav>
            </PageHeader>
        </Flex>
    );
};
export default TableHeader;