import React from 'react';
import {Col} from "@amzn/stencil-react-components/layout";
import {useParams} from "react-router";
import {FailureErrorView} from "src/pages/FailureErrorView";
import {GLOBAL_ERROR_MESSAGE_TO_ERROR_CODE} from "src/constants/Urls";
import {KatalLoggerClient} from "src/logger/KatalLoggerClient";
import {MetricUtils} from "src/utils/MetricUtils";

export const FailureErrorPage = () => {
    const {errorCode} = useParams() as {errorCode: string};
    const logger = new KatalLoggerClient();

    const getErrorViewByCode = (errorMessage: string) => {
        switch (GLOBAL_ERROR_MESSAGE_TO_ERROR_CODE[errorMessage]) {
            case '5xx':
                MetricUtils.pageLoadCountMetrics('5xx', logger);
                return <FailureErrorView errorCode={errorCode} />;
            default:
                return <FailureErrorView/>;
        }
    }

    return (
        <Col alignItems={'center'} padding={'3rem 1rem'} >
            {getErrorViewByCode(errorCode)}
        </Col>
    );
};