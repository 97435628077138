import React from 'react';

const Constant = {
    URL_SEPARATOR: '/',
    PARAM_IDENTIFIER: ':',
};

/**
 * It will take URL and params as input and replace the param values with the URL placeholders. Placeholder must start with ':'.
 * @param url Base URL to be parsed
 * @param params params to be added to url
 */
const prepareUrl = (url: string, ...params: string[]) => {
    const oldSegments = url.split(Constant.URL_SEPARATOR);
    const newSegments = oldSegments.map((segment) => {
        return segment.startsWith(Constant.PARAM_IDENTIFIER) ? params.shift() : segment;
    });
    return newSegments.join(Constant.URL_SEPARATOR);
};

export const UrlUtils = {
    prepareUrl,
};