import React from 'react';
import {useAsyncState} from "src/hooks/useAsyncState";
import {User} from "src/models/User";
import {Route, Router, Switch, useLocation, useParams} from "react-router-dom";
import GetUserDetails from "src/builders/GetUserDetails";
import {useContext, useEffect} from "react";
import {UserContext} from "src/components/Authentication/Authentication";
import ContentLoader from "src/components/ContentLoader";
import {SpinnerSize} from "@amzn/stencil-react-components/spinner";
import AccessDeniedPage from "src/pages/AccessDeniedPage";
import {ALLOW_LIST} from "src/components/Authentication/AllowListedUsers";
import {browserHistory} from "src/AppRoutes";
import {UrlUtils} from "src/utils/UrlUtils";
import {PAGE_PATHS} from "src/constants/Urls";
import {ERROR_CODE} from "src/constants/enums";
import {ERROR_CODE_4XX} from "src/constants/DisplayConstants";
import { isTRDEnabledDomain } from 'src/pages/TRD/TRDUtils';

const DomainAuthenticationComponent = (props) => {
	const userDetails = useAsyncState<User>();
	const {domainId}: {domainId: string} = useParams();
	const userContext = useContext(UserContext).authenticatedUser;
	const location = useLocation();
	const validateUserInDomain = async (employeeId, domainId) => {
		try {
			userDetails.initiateAsyncState();
			if (!ALLOW_LIST.has(employeeId)) {
				const getUserDetailsResponse = await GetUserDetails.getUserDetails(domainId, employeeId);
				userDetails.setAsyncSuccess(getUserDetailsResponse.user);
			} else {
				userDetails.setIsLoading(false);
			}
		} catch (error) {
			if (error.errorStatus != undefined && ERROR_CODE_4XX.test(error.errorStatus.toString())) {
				browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, error.errorStatus));
			} else {
				browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, ERROR_CODE.GENERIC_FAILURE));
			}
			userDetails.setAsyncFailure(error);
		}
	}
	/**
	 * This check helps prevent direct access to the TRD page via URL modification for domains that aren't enabled.
	 */
	const validateTRDPath = () => {
		if(!isTRDEnabledDomain(domainId) && location.pathname.includes("/trd")){
			browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, ERROR_CODE.GENERIC_FAILURE));
		}
	}
	useEffect(() => {
		validateTRDPath();
		validateUserInDomain(userContext.employeeId, domainId).then();
	}, []);
	if (userDetails.isLoading) {
		return <ContentLoader size={SpinnerSize.Large} loadingText={'Authenticating user'} fontSize={'T300'} />
	}
	return (ALLOW_LIST.has(userContext.employeeId) || userDetails.data) ? <Route>{props.children}</Route> : <AccessDeniedPage/>;
}
const DomainAuthenticatedRoutes = (props) => {
	return <Route {...props}><DomainAuthenticationComponent>{props.children}</DomainAuthenticationComponent></Route>
}


export default DomainAuthenticatedRoutes;