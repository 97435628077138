import {Col, Row, Spacer} from "@amzn/stencil-react-components/layout";
import {H4, H5, Text} from "@amzn/stencil-react-components/text";
import {StencilProvider} from "@amzn/stencil-react-components/context";
import TableHeader from "src/components/TableHeader";
import {ENTITY_TYPE, ERROR_CODE, SearchFilter} from "src/constants/enums";
import EntityTable from "src/components/EntityTable";
import React, {useContext, useEffect, useState} from "react";
import {TableRequiredFields} from "src/configurations/InputElementConfigs";
import {useParams} from "react-router-dom";
import GetUsersBuilder from "src/builders/GetUsersBuilder";
import ContentLoader from "src/components/ContentLoader";
import {SpinnerSize} from "@amzn/stencil-react-components/spinner";
import {useAsyncState} from "src/hooks/useAsyncState";
import {User} from "src/models/User";
import {Queue} from "src/models/Queue";
import GetQueuesBuilder from "src/builders/GetQueuesBuilder";
import {Button, ButtonVariant} from "@amzn/stencil-react-components/button";
import AddUsersToQueueModal from "src/pages/CaseQueues/AddUsersToQueueModal";
import {UserContext} from "src/components/Authentication/Authentication";
import {MessageBanner, MessageBannerType} from "@amzn/stencil-react-components/message-banner";
import LeftNavigationPanel from "src/components/LeftNavigationPanel/LeftNavigationPanel";
import {QueueUserOperationType} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import {browserHistory} from "src/AppRoutes";
import {UrlUtils} from "src/utils/UrlUtils";
import {PAGE_PATHS} from "src/constants/Urls";
import {
    ADD_USERS,
    ERROR_CODE_4XX,
    ERROR_MSG_BANNER_AUTO_DISMISS_TIME,
    MessageBannerDetails,
    REMOVE_USERS,
    SUCCESS_MSG_BANNER_AUTO_DISMISS_TIME
} from "src/constants/DisplayConstants";
import {getUserByEmployeeIDInQueue} from "src/utils/commons/CommonUtils";
import {ListOfDropdownActions} from "src/components/DropdownActions";
import CreateOrUpdateQueuesBuilder from "src/builders/CreateOrUpdateQueuesBuilder";
import UploadFileModal from "src/components/UploadFileModal";
import AutoDismissMessageBanner from "src/components/AutoDismissMessageBanner";
import {downloadQueuesFileTemplate, processQueueFiles} from "src/pages/CaseQueues/QueueUpdateProcessingUtils";

const QueueViewWithUsersManagement = () => {
    const userContext = useContext(UserContext).authenticatedUser;
    const { domainId, queueId }: { domainId: string, queueId: string } = useParams();
    const [ queueExists, setQueueExists ] = React.useState(false);
    const [uploadAddModalIsOpen, setUploadAddModalIsOpen] = useState(false);
    const [uploadRemoveModalIsOpen, setUploadRemoveModalIsOpen] = useState(false);
    const queueDetails = useAsyncState<Queue>();
    const usersInDomain = useAsyncState<User[]>();
    const [messageBannerDetails, setMessageBannerDetails] = useState<MessageBannerDetails>({});
    const [messageBannerVisible, setMessageBannerVisible] = useState(false);
    const flipBannerVisibility = () => {
        setMessageBannerVisible(!messageBannerVisible);
    }

    const dropdownActionList : ListOfDropdownActions[] = [
        { actionName : ADD_USERS , executeAction: () => {
                setUploadAddModalIsOpen(true);
            }
        },
        { actionName : REMOVE_USERS, executeAction: () => {
                setUploadRemoveModalIsOpen(true);
            }
        },
    ]
    const getQueueDetails = async (queueId) => {
        try {
            queueDetails.initiateAsyncState();
            const getQueuesResponse = await GetQueuesBuilder.getQueues(domainId, queueId);
            if (getQueuesResponse.queues && getQueuesResponse.queues.length == 1) {
                queueDetails.setAsyncSuccess(getQueuesResponse.queues[0]);
                setQueueExists(true);
            } else {
                queueDetails.setAsyncSuccess({} as Queue)
            }
        } catch (error) {
            if (error.errorStatus != undefined && ERROR_CODE_4XX.test(error.errorStatus.toString())) {
                browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, error.errorStatus));
            } else {
                browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, ERROR_CODE.GENERIC_FAILURE));
            }
            queueDetails.setAsyncFailure(error);
        }
    };

    const usersInQueue = useAsyncState<User[]>();

    const getUsersInQueue = async () => {
        try {
            usersInQueue.initiateAsyncState();
            const getUsersResponse = await GetUsersBuilder.getUsers(domainId, queueId);
            usersInQueue.setAsyncSuccess(getUsersResponse.users);
        } catch (error) {
            if (error.errorStatus != undefined && ERROR_CODE_4XX.test(error.errorStatus.toString())) {
                browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, error.errorStatus));
            } else {
                browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, ERROR_CODE.GENERIC_FAILURE));
            }
            usersInQueue.setAsyncFailure(error);
        }
    };
    const getUsersInDomain = async () => {
        try {
            usersInDomain.initiateAsyncState();
            const getUsersResponse = await GetUsersBuilder.getUsers(domainId);
            usersInDomain.setAsyncSuccess(getUsersResponse.users);
        } catch (error) {
            if (error.errorStatus != undefined && ERROR_CODE_4XX.test(error.errorStatus.toString())) {
                browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, error.errorStatus));
            } else {
                browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, ERROR_CODE.GENERIC_FAILURE));
            }
            usersInDomain.setAsyncFailure(error);
        }
    };

    useEffect(() => {
        getQueueDetails(queueId).then()
        getUsersInQueue().then()
        getUsersInDomain().then()
    }, []);

    const getUsersInDomainAndNotInQueue = (): User[] => {
        if (usersInDomain.data && usersInQueue.data) {
            return usersInDomain.data!.filter(user => !usersInQueue.data!.includes(user));
        } else {
            return [];
        }
    }
    const getUsersForSearchFilter = async (filterName, filterValue) => {
        if (filterName == SearchFilter.EMPLOYEE_ID) {
            await getUserByEmployeeIDInQueue(filterValue, queueId, queueDetails.data?.name, domainId, usersInQueue);
        }
    }

    const removeUsers = async (userIds: string[]) => {
        try {
            let queueDetailsCopy = { ...queueDetails.data };
            queueDetailsCopy['userOperation'] = {
                type: QueueUserOperationType.REMOVE,
                userIds: userIds
            };
            const editQueueResponse = await CreateOrUpdateQueuesBuilder.editQueue(domainId, userContext.alias, queueDetailsCopy);
            setMessageBannerDetails({
                messageBannerText: `Successfully removed user: ${userIds}`,
                messageBannerType: MessageBannerType.Success,
                messageBannerAutoDismiss: SUCCESS_MSG_BANNER_AUTO_DISMISS_TIME
            })
            getUsersInQueue();
            flipBannerVisibility()
        } catch (error) {
            setMessageBannerDetails({
                messageBannerText: `User removal failed with error: ${error.errorMessage}`,
                messageBannerType: MessageBannerType.Error,
                messageBannerAutoDismiss: ERROR_MSG_BANNER_AUTO_DISMISS_TIME
            })
            flipBannerVisibility()
        }
    };

    if (queueDetails.isLoading) {
        return <ContentLoader size={SpinnerSize.Large} loadingText={'Loading Queue Details'} fontSize={'T300'}/>
    }

    const actionColumn = [
        {
            cellComponent: ({ data }) => (
                <Button
                    id={"remove-user-button"}
                    aria-describedby="sroDialog"
                    variant={ButtonVariant.Tertiary}
                    onClick={(e) => {
                        removeUsers([ data.userId ]).then();
                        getUsersInQueue().then();
                    }}
                >
                    Remove
                </Button>
            ),
            header: 'Action'
        }
    ];

    if (!queueExists) {
        return (
            <>
                <Row width={'100%'} justifyContent={'center'} flexWrap={'wrap'}>
                    <Col justifyContent={'center'}>
                        <MessageBanner type={MessageBannerType.Warning}>
                            Queue with ID: {queueId} does not exist.
                        </MessageBanner>
                    </Col>
                </Row>
            </>
        )
    }

    // TODO: create grid like structure to pass queueDetails with row and column size.
    return (
        <Row>
            <LeftNavigationPanel/>
            <Col width={'80%'}>
                {messageBannerVisible ? <AutoDismissMessageBanner
                    bannerText={messageBannerDetails.messageBannerText}
                    bannerType={messageBannerDetails.messageBannerType}
                    bannerAutoDismissTime={messageBannerDetails.messageBannerAutoDismiss}
                    flipVisibility={flipBannerVisibility}
                /> : null}
                <H4 textAlign={'center'}>{queueDetails.data?.name}</H4>
                <Spacer height={15}/>
                <Row justifyContent={'center'} border={'2px solid neutral20'} margin={'30px'} padding={'1rem'}>
                    <Col width={'33%'} gridGap={'5px'}>
                        <H5>Queue Id</H5>
                        <Text color={'neutral70'}>{queueDetails.data?.queueId}</Text>
                    </Col>
                    <Col width={'33%'} gridGap={'5px'}>
                        <H5>Description</H5>
                        <Text color={'neutral70'}>{queueDetails.data?.description}</Text>
                    </Col>
                    <Col width={'33%'}>
                        <H5>Additional Attributes</H5>
                        <Text color={'neutral70'}>{queueDetails.data?.additionalAttributes}</Text>
                    </Col>
                </Row>
                <Col margin={'30px'}>
                    <StencilProvider>
                        { uploadAddModalIsOpen ?
                            <UploadFileModal onClose={() => setUploadAddModalIsOpen(false)}
                                             processFile={processQueueFiles(domainId, userContext.alias, QueueUserOperationType.ADD)}
                                             downloadFileTemplate={downloadQueuesFileTemplate}
                                             onTableReload={getUsersInQueue}/>
                            : null }
                        { uploadRemoveModalIsOpen ?
                            <UploadFileModal onClose={() => setUploadRemoveModalIsOpen(false)}
                                             processFile={processQueueFiles(domainId, userContext.alias, QueueUserOperationType.REMOVE)}
                                             downloadFileTemplate={downloadQueuesFileTemplate}
                                             onTableReload={getUsersInQueue}/>
                            : null }
                        <TableHeader entityName={ENTITY_TYPE.USER}
                                     dropdownActions={{ listOfActions: dropdownActionList,
                                         csvExportEnabled: true,
                                         fileData: usersInQueue,
                                         fileName: 'user list',
                                     }}
                                     CustomCreateModal={<AddUsersToQueueModal
                                         users={getUsersInDomainAndNotInQueue()}
                                         queue={queueDetails.data!}
                                         domainId={domainId}
                                         onTableReload={getUsersInQueue}
                                         setMessageBannerDetails={setMessageBannerDetails}
                                         flipBannerVisibility={flipBannerVisibility}
                                     />}
                                     onTableReload={getUsersInQueue}
                                     searchByFilter={getUsersForSearchFilter}/>
                        { usersInQueue.isLoading ? <ContentLoader
                                size={SpinnerSize.Large}
                                loadingText={'Loading Queue User'}
                                fontSize={'T300'}/> :
                            <EntityTable data={usersInQueue.data != undefined ? usersInQueue.data : []}
                                         requiredFields={TableRequiredFields[ENTITY_TYPE.USER]}
                                         customActionColumn={actionColumn}
                                         domainId={domainId}
                                         onTableReload={getUsersInQueue}/> }
                    </StencilProvider>
                </Col>
            </Col>
        </Row>
    )
}

export default QueueViewWithUsersManagement