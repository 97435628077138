import AxiosHttpClient from 'src/utils/HttpUtils/AxiosHttpClient';
import { API_ENDPOINTS } from 'src/constants/Urls';
import { ServiceConstants } from 'src/constants/ServiceConstants';
import {
    CreateOrUpdateProfilePermissionsRequest,
    CreateOrUpdateUsersRequest, GetProfilePermissionsRequest,
    GetQueuesRequest,
    GetUsersRequest,
    CreateOrUpdateProfilesRequest,
    CreateOrUpdateQueuesRequest,
    GetProfilesRequest, GetUserDetailsRequest, SearchEmployeeRequest,
    GetUserGroupsRequest, CreateOrUpdateUserGroupsRequest
} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import {AxiosResponse} from "axios";

const getQueues = (getQueuesRequest: GetQueuesRequest): Promise<AxiosResponse> => {
    const config = {
        url: API_ENDPOINTS.GET_QUEUES,
        method: ServiceConstants.POST,
    };
    return AxiosHttpClient.request(config, getQueuesRequest);
};

const getUsers = (getUsersRequest: GetUsersRequest): Promise<AxiosResponse> => {
    const config = {
        url:  API_ENDPOINTS.GET_USERS,
        method: ServiceConstants.POST,
    };
    return AxiosHttpClient.request(config, getUsersRequest);
};


const getProfilePermissions = (
    getProfilePermissionRequest: GetProfilePermissionsRequest
): Promise<AxiosResponse> => {
    const config = {
        url:  API_ENDPOINTS.GET_PROFILE_PERMISSIONS,
        method: ServiceConstants.POST,
    };
    return AxiosHttpClient.request(config, getProfilePermissionRequest);
};

const getUserDetails = (
    getUserDetailsRequest: GetUserDetailsRequest
): Promise<AxiosResponse> => {
    const config = {
        url: API_ENDPOINTS.GET_USER_DETAILS,
        method: ServiceConstants.POST,
    }
    return AxiosHttpClient.request(config, getUserDetailsRequest);
}


const createOrUpdateQueue = (createOrUpdateQueueRequest: CreateOrUpdateQueuesRequest): Promise<AxiosResponse> => {
    const config = {
        url:  API_ENDPOINTS.CREATE_OR_UPDATE_QUEUE,
        method: ServiceConstants.POST,
    };
    return AxiosHttpClient.request(config, createOrUpdateQueueRequest);
}

const createOrUpdateUserGroup = (createOrUpdateUserGroupRequest: CreateOrUpdateUserGroupsRequest): Promise<AxiosResponse> => {
    const config = {
        url:  API_ENDPOINTS.CREATE_OR_UPDATE_USER_GROUP,
        method: ServiceConstants.POST,
    };
    return AxiosHttpClient.request(config, createOrUpdateUserGroupRequest);
}

const createOrUpdateUser = (createOrUpdateUserRequest: CreateOrUpdateUsersRequest): Promise<AxiosResponse> => {
    const config = {
        url: API_ENDPOINTS.CREATE_OR_UPDATE_USER,
        method: ServiceConstants.POST,
    };
    return AxiosHttpClient.request(config, createOrUpdateUserRequest);
}


const getProfiles = (getProfilesRequest: GetProfilesRequest): Promise<AxiosResponse> => {
    const config = {
        url:  API_ENDPOINTS.GET_PROFILES,
        method: ServiceConstants.POST,
    };
    return AxiosHttpClient.request(config, getProfilesRequest);
};

const createOrUpdateProfile = (createOrUpdateProfileRequest: CreateOrUpdateProfilesRequest): Promise<AxiosResponse> => {
    const config = {
        url: API_ENDPOINTS.CREATE_OR_UPDATE_PROFILE,
        method: ServiceConstants.POST,
    };
    return AxiosHttpClient.request(config, createOrUpdateProfileRequest);
}


const createOrUpdateProfilePermission = (
    createOrUpdateProfilePermissionsRequest: CreateOrUpdateProfilePermissionsRequest
): Promise<AxiosResponse> => {
    const config = {
        url:  API_ENDPOINTS.CREATE_OR_UPDATE_PROFILE_PERMISSIONS,
        method: ServiceConstants.POST,
    };
    return AxiosHttpClient.request(config, createOrUpdateProfilePermissionsRequest);
};

const searchEmployee = (
    searchEmployeeRequest: SearchEmployeeRequest
): Promise<AxiosResponse> => {
    const config = {
        url:  API_ENDPOINTS.SEARCH_EMPLOYEE,
        method: ServiceConstants.POST
    }
    return AxiosHttpClient.request(config, searchEmployeeRequest);
}

const getUserGroups = (
    getUserGroupsRequest: GetUserGroupsRequest
): Promise<AxiosResponse> => {
    const config = {
        url:  API_ENDPOINTS.GET_USER_GROUPS,
        method: ServiceConstants.POST
    }
    return AxiosHttpClient.request(config, getUserGroupsRequest);
}

export const CaseAuthorityService = {
    getQueues,
    createOrUpdateQueue,
    getUsers,
    createOrUpdateUser,
    getProfiles,
    createOrUpdateProfile,
    getProfilePermissions,
    createOrUpdateProfilePermission,
    createOrUpdateUserGroup,
    getUserDetails,
    searchEmployee,
    getUserGroups
}
