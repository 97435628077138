import {EXTERNAL_URLS} from "src/constants/Urls";
import {DOMAIN_TO_STAGE, STAGE} from "src/constants/enums";

export const getCurrentStage = (): STAGE => {
  if (process.env.NODE_ENV !== 'production' || DOMAIN_TO_STAGE[window.location.host] === undefined) {
    return STAGE.ALPHA;
  }
  return DOMAIN_TO_STAGE[window.location.host];
};

export const getStageExternalURLs = () => {
  return EXTERNAL_URLS[getCurrentStage()];
};
