import React from "react";
import {MessageBanner, MessageBannerType} from "@amzn/stencil-react-components/message-banner";
import {Col, View} from "@amzn/stencil-react-components/layout";

interface AutoDismissMessageBannerProps {
    bannerText?: string,
    bannerType?: MessageBannerType.Success | MessageBannerType.Error,
    bannerAutoDismissTime?: number,
    flipVisibility: Function,
}
const AutoDismissMessageBanner = ({bannerText, bannerType, bannerAutoDismissTime, flipVisibility} : AutoDismissMessageBannerProps) =>  {
    return (
        <Col gridGap="S200">
            <View height={50}>
                <MessageBanner
                    type={bannerType}
                    isDismissible
                    autoDismissAfter={bannerAutoDismissTime}
                    onDismissed={() => {
                        flipVisibility();
                    }}>
                    {bannerText}
                </MessageBanner>
            </View>
        </Col>
    )
};
export default AutoDismissMessageBanner;
