import {Row} from "@amzn/stencil-react-components/layout";
import {H1} from "@amzn/stencil-react-components/text";
import React from "react";

const AccessDeniedPage = () => {
	return (
		<>
			<Row width={'100%'} justifyContent={'center'} flexWrap={'wrap'}>
				<H1 fontWeight={400} fontSize={'26px'} margin={'2rem 0rem 0rem 1rem'}>
					You do not have access to view this page.
				</H1>
			</Row>
		</>
	)
}

export default AccessDeniedPage