import React, {lazy} from 'react';
import { createBrowserHistory } from 'history';
import { Route, Switch, Router } from 'react-router-dom';
import {SpinnerSize} from '@amzn/stencil-react-components/spinner';
import {PAGE_PATHS} from 'src/constants/Urls';
import AppErrorBoundary from "src/components/App/AppErrorBoundary";
import QueueViewWithUsersManagement from "src/pages/CaseQueues/QueueViewWithUsersManagement";
import ContentLoader from "src/components/ContentLoader/ContentLoader";
import DomainAuthenticatedRoutes from "src/components/Authentication/DomainAuthenticatedRoutes";
import {FailureErrorPage} from "src/pages/FailureErrorPage";
import UserGroupViewWithUsersManagement from "src/pages/UserGroups/UserGroupViewWithUsersManagement";

export const browserHistory = createBrowserHistory();

const Users = lazy(() => import('src/pages/Users/Users'));
const UserDetails = lazy(() => import('src/pages/Users/UserDetails'));
const Profiles = lazy(() => import('src/pages/Profiles/Profiles'));
const Permissions = lazy(() => import('src/pages/ProfilePermissions/ProfileViewWithPermissionsManagement'));
const CaseQueues = lazy(() => import('src/pages/CaseQueues/CaseQueues'));
const UserGroups = lazy(() => import('src/pages/UserGroups/UserGroups'));
const Domains = lazy(() => import('src/pages/DomainsManagement/Domains'));
const LandingPage = lazy(() => import('src/pages/LandingPage'));
const TRDPage = lazy(() => import('src/pages/TRD/TRDWorkflow'));
const BulkCaseManagement = lazy(() => import('src/pages/BulkCaseManagement/CaseRelatedBulkOperations'));
const PageNotFoundPage = lazy(() => import('src/pages/PageNotFoundPage'));

const AppRoutes = () => {
    return (
        <Router history={browserHistory}>
            <React.Suspense
                fallback={
                    <ContentLoader size={SpinnerSize.Large} loadingText={'Initializing'} fontSize={'T300'} />
                }
            >
                <AppErrorBoundary>
                    <Switch>
                        <Route path={PAGE_PATHS.HOME} exact component={LandingPage} />
                        <Route path={PAGE_PATHS.DOMAINS} exact component={Domains} />
                        <DomainAuthenticatedRoutes path={PAGE_PATHS.DOMAIN_VIEW}>
                            <Route path={PAGE_PATHS.USERS} exact component={Users} />
                            <Route path={PAGE_PATHS.USER_DETAILS} exact component={UserDetails} />
                            <Route path={PAGE_PATHS.PROFILE_VIEW_WITH_PERMISSIONS_MANAGEMENT} exact component={Permissions}/>
                            <Route path={PAGE_PATHS.CASE_QUEUES} exact component={CaseQueues}/>
                            <Route path={PAGE_PATHS.PROFILES} exact component={Profiles} />
                            <Route path={PAGE_PATHS.QUEUE_VIEW_WITH_USER_MANAGEMENT} exact component={QueueViewWithUsersManagement} />
                            <Route path={PAGE_PATHS.USER_GROUP_VIEW_WITH_USER_MANAGEMENT} exact component={UserGroupViewWithUsersManagement} />
                            <Route path={PAGE_PATHS.USER_GROUP} exact component={UserGroups} />
                            <Route path={PAGE_PATHS.TRD} exact component={TRDPage}/>
                            <Route path={PAGE_PATHS.BULK_CASE_MANAGEMENT} exact component={BulkCaseManagement}/>
                        </DomainAuthenticatedRoutes>
                        <Route path={PAGE_PATHS.FAILURE_PAGE} exact component={FailureErrorPage} />
                        <Route path={'*'} component={PageNotFoundPage} />
                    </Switch>
                </AppErrorBoundary>
            </React.Suspense>
        </Router>
    );
};

export default AppRoutes;