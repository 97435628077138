import {GetQueuesRequest} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import {DEFAULT_REQUEST_PAGE_SIZE} from "src/constants/DisplayConstants";
import AsyncRequestHandler from "src/utils/HttpUtils/HttpHandler";
import {CaseAuthorityService} from "src/service/CaseAuthorityService";
import {GetQueuesResponse} from "src/models/ServiceResponses/CaseAuthorityServiceResponses";
import {ExceptionDetail} from "src/models/ExceptionDetail";
import { Queue } from "src/models/Queue";
import { logger } from "src/logger/KatalLoggerClient";

const getQueues = async (domainId: string, queueId?: string, queueName?: string, userId?: string) => {
    try {
        const getQueuesRequest = buildGetQueuesRequest(domainId!, DEFAULT_REQUEST_PAGE_SIZE, queueId,  queueName, userId);
        const response = await AsyncRequestHandler(CaseAuthorityService.getQueues(getQueuesRequest));
        return buildGetQueuesResponse(response);
    } catch (error) {
        logger.error('Fetch Queues call failed', error.message).then();
        throw buildGetQueuesException(error);
    }
};
const buildGetQueuesRequest = (domainId: string, pageSize: number, queueId?: string, queueName?: string, userId?: string, pageToken?: string,): GetQueuesRequest =>  {
    if (pageToken) {
        return {domainId: domainId, queueId: queueId, queueNameWildcardKeyword: queueName, pageSize: pageSize, userId: userId, pageToken: pageToken}
    }
    return {domainId: domainId, queueId: queueId, queueNameWildcardKeyword: queueName, pageSize: pageSize, userId: userId}
}
const buildQueue = (queue) => {
  const queueObject = {
    ...queue
  }
  if(!("additionalAttributes" in queueObject)){
    queueObject['additionalAttributes'] = "null"
  }
  return queueObject;
}

const buildGetQueuesResponse = (response): GetQueuesResponse => {
  const queuesList: Queue[] = response.queues.map((queue) => {
     return buildQueue(queue);
  });
  
    return {
        ...response,
      queues: queuesList,
    } as GetQueuesResponse;
};

const buildGetQueuesException = (error): ExceptionDetail => {
    return {
        errorCode: error.response.data.errorCode,
        errorStatus: error.response.status,
        errorMessage: error.message,
        retryable: error.retryable,
    } as ExceptionDetail;
};


const GetQueuesBuilder = {
    getQueues,
};

export default GetQueuesBuilder;