import axios, {AxiosRequestConfig} from 'axios';
import {Cookies} from "react-cookie";
import {USER_AUTHORIZATION_TOKEN_KEY} from "src/constants/AccessConstants";
import {API_ENDPOINTS} from "src/constants/Urls";

/**
 * Default config for API call via axios.
 */
const AXIOS_DEFAULT_CONFIG = {
    withCredentials: true,
};

/**
 * Take service config merge with axios default config.
 * Create an instance of axios request.
 */
const request = async (requestConfig: any, data: any, addAuthorizationHeader = true) => {
    const cookies =  new Cookies();
    requestConfig.params = { ...requestConfig.params };
    let config = { ...AXIOS_DEFAULT_CONFIG, ...requestConfig };
    config.data = data ? data : null;
    config.headers = {
        'content-type': 'application/json',
    };
    if (addAuthorizationHeader) {
        config.headers.Authorization = cookies.get(USER_AUTHORIZATION_TOKEN_KEY);
    }
    if(process.env.NODE_ENV !== "production") {
        config = {...config, ...devEnvRequestConfig(config)};
    }
    console.log(config);
    return axios.request(config);
};

const devEnvRequestConfig = (config: AxiosRequestConfig) => {
    if (config.url == API_ENDPOINTS.CREATE_BULK_OPERATION || config.url == API_ENDPOINTS.GET_BULK_OPERATION_STATUS ||
    config.url == API_ENDPOINTS.GET_UPLOAD_ASSET_URL) {
        return {
            url: "https://kxk2gyh3v6.execute-api.us-west-2.amazonaws.com/Alpha" + config.url,
            withCredentials: false,
        }
    }
    return {
        url: "https://pxtcms-admin-console.us-west-2.alpha.cases.pxt.amazon.dev" + config.url,
        withCredentials: false,
    };
};

/**
 * Http Provider is abstraction layer between axios and other services, components.
 */
const AxiosHttpClient = {
    request: request,
};

export default AxiosHttpClient;