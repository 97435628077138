export const DEFAULT_INITIAL_ATTRIBUTES = {key: "", value: ""};

export interface AdditionalAttributes {
    key: string;
    value: string;
}
export const buildAdditionalAttributeArray = (data) => {
    let additionalAttributeArray : Array<AdditionalAttributes> = [];
    const additionalAttributesParsed: Map<string, string> = data === undefined ? {} : JSON.parse(data);
    if(additionalAttributesParsed === null || Object.keys(additionalAttributesParsed).length === 0) {
        additionalAttributeArray.push({...DEFAULT_INITIAL_ATTRIBUTES})
    }
    for(const attribute in additionalAttributesParsed){
        additionalAttributeArray.push({key: attribute , value: additionalAttributesParsed[attribute]})
    }
    return additionalAttributeArray;
}

// buildAdditionalAttributes to create a new attribute in key: value manner
export const buildAdditionalAttributes = (additionalAttribute) => {
    let additionalAttributeDetails: Record<string, string> = {};
    additionalAttribute.forEach(function (attribute) {
        if((attribute.key !== '' && attribute.value !== ''))
            additionalAttributeDetails[attribute.key] = attribute.value;
    });
    return additionalAttributeDetails;
}