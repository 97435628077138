import {Col, Row, Spacer} from "@amzn/stencil-react-components/layout";
import {H4, H5, Text} from "@amzn/stencil-react-components/text";
import {StencilProvider} from "@amzn/stencil-react-components/context";
import TableHeader from "src/components/TableHeader";
import {ENTITY_TYPE, ERROR_CODE, SearchFilter} from "src/constants/enums";
import EntityTable from "src/components/EntityTable";
import {TableRequiredFields} from "src/configurations/InputElementConfigs";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import GetUsersBuilder from "src/builders/GetUsersBuilder";
import ContentLoader from "src/components/ContentLoader";
import {SpinnerSize} from "@amzn/stencil-react-components/spinner";
import {useAsyncState} from "src/hooks/useAsyncState";
import {User} from "src/models/User";
import {Button, ButtonVariant} from "@amzn/stencil-react-components/button";
import {UserContext} from "src/components/Authentication/Authentication";
import {MessageBanner, MessageBannerType} from "@amzn/stencil-react-components/message-banner";
import LeftNavigationPanel from "src/components/LeftNavigationPanel/LeftNavigationPanel";
import {UserGroupOperationType} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import {browserHistory} from "src/AppRoutes";
import {UrlUtils} from "src/utils/UrlUtils";
import {PAGE_PATHS} from "src/constants/Urls";
import {
    ERROR_CODE_4XX, ERROR_MSG_BANNER_AUTO_DISMISS_TIME, GLOBAL,
    MessageBannerDetails,
    SUCCESS_MSG_BANNER_AUTO_DISMISS_TIME
} from "src/constants/DisplayConstants";
import GetUserGroupsBuilder from "src/builders/GetUserGroupsBuilder";
import {UserGroup} from "src/models/UserGroup";
import AddUsersToUserGroupModal from "src/pages/UserGroups/AddUsersToUserGroupModal";
import {getUserByEmployeeIDInUserGroup} from "src/utils/commons/CommonUtils";
import AutoDismissMessageBanner from "src/components/AutoDismissMessageBanner";
import CreateOrUpdateUserGroupsBuilder from "src/builders/CreateOrUpdateUserGroups";

const UserGroupViewWithUsersManagement = () => {
    const userContext = useContext(UserContext).authenticatedUser;
    const { domainId, userGroupId } : { domainId: string, userGroupId: string } = useParams();
    const [userGroupExists, setUserGroupExists] = React.useState(false);
    const userGroupDetails = useAsyncState<UserGroup>();
    const usersInDomain = useAsyncState<User[]>();
    const [messageBannerDetails, setMessageBannerDetails] = useState<MessageBannerDetails>({});
    const [messageBannerVisible, setMessageBannerVisible] = useState(false);
    const flipBannerVisibility = () => {
        setMessageBannerVisible(!messageBannerVisible);
    }
    const getUserGroupDetails = async (userGroupId) => {
        try {
            userGroupDetails.initiateAsyncState();
            const getUserGroupsResponse = await GetUserGroupsBuilder.getUserGroups(domainId,undefined, userGroupId);
            if (getUserGroupsResponse.userGroups && getUserGroupsResponse.userGroups.length == 1) {
                userGroupDetails.setAsyncSuccess(getUserGroupsResponse.userGroups[0]);
                setUserGroupExists(true);
            } else {
                userGroupDetails.setAsyncSuccess({} as UserGroup)
            }
        } catch (error) {
            if (error.errorStatus != undefined && ERROR_CODE_4XX.test(error.errorStatus.toString())) {
                browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, error.errorStatus));
            } else {
                browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, ERROR_CODE.GENERIC_FAILURE));
            }
            userGroupDetails.setAsyncFailure(error);
        }
    };

    const usersInGroup = useAsyncState<User[]>();

    const getUsersInGroup = async () => {
        try {
            usersInGroup.initiateAsyncState();
            const getUsersResponse = await GetUsersBuilder.getUsers(domainId,undefined, userGroupId);
            usersInGroup.setAsyncSuccess(getUsersResponse.users);
        } catch (error) {
            if (error.errorStatus != undefined && ERROR_CODE_4XX.test(error.errorStatus.toString())) {
                browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, error.errorStatus));
            } else {
                browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, ERROR_CODE.GENERIC_FAILURE));
            }
            usersInGroup.setAsyncFailure(error);
        }
    };
    const getUsersInDomain = async () => {
        try {
            usersInDomain.initiateAsyncState();
            const getUsersResponse = await GetUsersBuilder.getUsers(domainId);
            usersInDomain.setAsyncSuccess(getUsersResponse.users);
        } catch (error) {
            if (error.errorStatus != undefined && ERROR_CODE_4XX.test(error.errorStatus.toString())) {
                browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, error.errorStatus));
            } else {
                browserHistory.push(UrlUtils.prepareUrl(PAGE_PATHS.FAILURE_PAGE, ERROR_CODE.GENERIC_FAILURE));
            }
            usersInDomain.setAsyncFailure(error);
        }
    };

    useEffect(() => {
        getUserGroupDetails(userGroupId).then()
        getUsersInGroup().then()
        getUsersInDomain().then()
    }, []);

    const getUsersInDomainAndNotInUserGroup = (): User[] => {
        if (usersInDomain.data && usersInGroup.data) {
            return usersInDomain.data!.filter(user => !usersInGroup.data!.includes(user));
        } else {
            return [];
        }
    }

    const getUsersForSearchFilter = async (filterName, filterValue) => {
        if (filterName == SearchFilter.EMPLOYEE_ID) {
            await getUserByEmployeeIDInUserGroup(filterValue, userGroupId, domainId, usersInGroup);
        }
    }

    const removeUsers = async (ids: string[]) => {
        try {
            let queueDetailsCopy = { ...userGroupDetails.data };
            if (domainId == GLOBAL) {
                queueDetailsCopy['userOperation'] = {
                    type: UserGroupOperationType.REMOVE,
                    userIds: undefined,
                    employeeIds: ids
                };
            } else {
                queueDetailsCopy['userOperation'] = {
                    type: UserGroupOperationType.REMOVE,
                    userIds: ids,
                    employeeIds: undefined
                };
            }
            await CreateOrUpdateUserGroupsBuilder.editUserGroup(domainId, userContext.alias, queueDetailsCopy);
            setMessageBannerDetails({
                messageBannerText: `Successfully removed user: ${ids}`,
                messageBannerType: MessageBannerType.Success,
                messageBannerAutoDismiss: SUCCESS_MSG_BANNER_AUTO_DISMISS_TIME
            })
            getUsersInGroup();
            flipBannerVisibility()
        } catch (error) {
            setMessageBannerDetails({
                messageBannerText: `User removal failed with error: ${error.errorMessage}`,
                messageBannerType: MessageBannerType.Error,
                messageBannerAutoDismiss: ERROR_MSG_BANNER_AUTO_DISMISS_TIME
            })
            flipBannerVisibility()
        }
    };

    if (usersInGroup.isLoading) {
        return <ContentLoader size={SpinnerSize.Large} loadingText={'Loading Users'} fontSize={'T300'} />
    }

    const actionColumn = [
        {
            cellComponent: ({ data }) => (
                <Button
                    aria-describedby="sroDialog"
                    variant={ButtonVariant.Tertiary}
                    onClick={ (e) => {
                        if (domainId == GLOBAL) {
                            removeUsers([data.employeeId]).then();
                        } else {
                            removeUsers([data.userId]).then();
                        }
                        getUsersInGroup().then();
                    }}
                >
                    Remove
                </Button>
            ),
            header: 'Action'
        }
    ];

    if (!userGroupExists) {
        return (
            <>
                <Row width={'100%'} justifyContent={'center'} flexWrap={'wrap'}>
                    <Col justifyContent={'center'}>
                        <MessageBanner type={MessageBannerType.Warning}>
                            UserGroup with ID: {userGroupId} does not exist.
                        </MessageBanner>
                    </Col>
                </Row>
            </>
        )
    }

    // TODO: create grid like structure to pass userGroupDetails with row and column size.
    return (
        <Row>
            <LeftNavigationPanel/>
            <Col width={'80%'}>
                {messageBannerVisible ? <AutoDismissMessageBanner
                    bannerText={messageBannerDetails.messageBannerText}
                    bannerType={messageBannerDetails.messageBannerType}
                    bannerAutoDismissTime={messageBannerDetails.messageBannerAutoDismiss}
                    flipVisibility={flipBannerVisibility}
                /> : null}
                <H4 textAlign={'center'}>{userGroupDetails.data?.name}</H4>
                <Spacer height={15} />
                <Row justifyContent={'center'} border={'2px solid neutral20'} margin={'30px'} padding={'1rem'}>
                    <Col width={'33%'} gridGap={'5px'}>
                        <H5>UserGroup Id</H5>
                        <Text color={'neutral70'}>{userGroupDetails.data?.userGroupId}</Text>
                    </Col>
                    <Col width={'33%'} gridGap={'5px'}>
                        <H5>Description</H5>
                        <Text color={'neutral70'}>{userGroupDetails.data?.description}</Text>
                    </Col>
                    <Col width={'33%'}>
                        <H5>Additional Attributes</H5>
                        <Text color={'neutral70'}>{userGroupDetails.data?.additionalAttributes}</Text>
                    </Col>
                </Row>
                <Col margin={'30px'}>
                    <StencilProvider>
                        <TableHeader entityName={ENTITY_TYPE.USER}
                                     CustomCreateModal={<AddUsersToUserGroupModal
                                         users={getUsersInDomainAndNotInUserGroup()}
                                         userGroup={userGroupDetails.data!}
                                         domainId={domainId}
                                         onTableReload={getUsersInGroup}
                                         setMessageBannerDetails={setMessageBannerDetails}
                                         flipBannerVisibility={flipBannerVisibility}
                                     />}
                                     onTableReload={getUsersInGroup}
                                     searchByFilter={getUsersForSearchFilter}/>
                        <EntityTable data={usersInGroup.data != undefined ? usersInGroup.data : []}
                                     requiredFields={TableRequiredFields[ENTITY_TYPE.USER]} customActionColumn={actionColumn} domainId={domainId} onTableReload={getUsersInGroup} />
                    </StencilProvider>
                </Col>
            </Col>
        </Row>
    )
}

export default UserGroupViewWithUsersManagement