import React, {useState} from "react";
import {Modal} from "@amzn/stencil-react-components/modal";
import {FileUpload} from "@amzn/stencil-react-components/file-upload";
import {Col, Row} from "@amzn/stencil-react-components/layout";
import {Text, H1} from "@amzn/stencil-react-components/text";
import {Button, ButtonVariant} from "@amzn/stencil-react-components/button";
import {IconDownload} from "@amzn/stencil-react-components/icons";

interface UploadFileModalProps {
    onClose: Function,
    processFile: (files: File[]) => void,
    downloadFileTemplate: Function,
    onTableReload: Function,
}
const UploadFileModal = ( {onClose, processFile, downloadFileTemplate, onTableReload}: UploadFileModalProps) => {
    const [isOpen, setIsOpen] = useState(true);
    const [file, setFile] = useState([] as File[]);
    const close = () => {
        if (onClose) {
            onClose();
        }
        setFile([]);
        setIsOpen(false);
    };

    const onFileAttached = (files: File[]) => {
        setFile(files);
    }

    return (
        <Modal isOpen={isOpen} close={close}>
            <Col maxWidth="90vw"
                 minWidth="40vw"
                 backgroundColor="neutral0"
                 gridGap="S500"
                 padding="S500"
                 alignItems="flex-start"
                 >
                <H1 fontWeight={400} fontSize={'25px'}>Download Template .csv File</H1>
                <Text>(Last column for entity ID can be omitted in case of creation.)</Text>
                <Button
                    icon={<IconDownload />}
                    onClick={() => { downloadFileTemplate() }}
                >
                    Download
                </Button>
                <H1 fontWeight={400} fontSize={'25px'}>File Upload</H1>
                <FileUpload
                    accept=".csv"
                    maxFiles={1}
                    isMulti={false}
                    onFileAttached={onFileAttached}
                />
                <Row
                    gridGap="S100"
                    alignSelf="flex-end">
                    <Button onClick={() => {
                                        processFile(file);
                                        onTableReload();
                                        close();
                                    }}
                        variant={ButtonVariant.Primary}>
                        Confirm Upload
                    </Button>
                </Row>
            </Col>
        </Modal>
    )
}

export default UploadFileModal