import {BulkExceptionDetail, ExceptionDetail} from "src/models/ExceptionDetail";

const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })

    const htmlAnchorElement = document.createElement('a')
    htmlAnchorElement.download = fileName
    htmlAnchorElement.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    htmlAnchorElement.dispatchEvent(clickEvt)
    htmlAnchorElement.remove()
}

const downloadCsvWithHeader = ({headers, fileName}) => {
    downloadFile({
        data: [...headers],
        fileName: fileName,
        fileType: 'text/csv',
    })
}

const downloadSuccessFailureLog = (operationName: string, successResults: string[], failureResults: BulkExceptionDetail[]) => {
    let fileData: string[] = [];
    fileData.push(`Successful ${operationName} responses: \n`);
    for (const result of successResults) {
        fileData.push(result + '\n');
    }
    fileData.push(`\nFailed ${operationName} errors: \n`);
    for (const result of failureResults) {
        const exceptionDetails = result.exceptionDetail;
        const requestDetails = result.requestDetails;
        fileData.push(`Request with payload ${JSON.stringify(requestDetails)} failed with error: ${JSON.stringify(exceptionDetails)}` + '\n');
    }
    downloadFile({
        data: fileData,
        fileName:`${operationName}Results.txt`,
        fileType: 'text/plain'
    });
}
export const FileUtils = {
    downloadCsvWithHeader, downloadFile, downloadSuccessFailureLog
}