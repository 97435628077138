import { AxiosError, AxiosResponse } from 'axios';

type FailureHandler = { (error: AxiosError): Promise<any> };

/**
 * Component to handle all API calls. It takes the API call method and updates the state according to the action provided
 * @param request
 * @param onFailure
 * @constructor
 */
const AsyncRequestHandler = async <T = unknown>(
    request: Promise<AxiosResponse<T>>,
    onFailure: FailureHandler = defaultFailureHandler,
): Promise<T> => {
    try {
        console.log('Request', request);
        const response: AxiosResponse = await request;
        return response.data;
    } catch (error) {
        console.error('Async request failed with error ', error);
        return onFailure(error);
    }
};

export default AsyncRequestHandler;

/**
 * Default failure handler for Async requests
 */
export const defaultFailureHandler = (error: AxiosError) => {
    throw error;
};