import React from 'react';
import {StencilProvider} from '@amzn/stencil-react-components/context';
import {MainWithSkipLink} from "@amzn/stencil-react-components/a11y";
import AppRoutes from "src/AppRoutes";
import NavigationBar from "src/components/NavigationBar";
import {PageContainer} from "@amzn/stencil-react-components/page";
import Authentication from "src/components/Authentication";
const App = () => {
    return (
        <StencilProvider>
            <Authentication>
                <NavigationBar />
                <MainWithSkipLink>
                    <PageContainer isFullWidth={true}>
                        <AppRoutes />
                    </PageContainer>
                </MainWithSkipLink>
            </Authentication>
        </StencilProvider>
    );
}
export default App;
