export enum ENTITY_TYPE {
    QUEUE = 'QUEUE',
    PROFILE = 'PROFILE',
    PROFILE_PERMISSIONS = 'PROFILE_PERMISSIONS',
    USER = 'USER',
    USER_GROUPS = 'USER_GROUPS',
    QUEUE_TO_USER_MAPPINGS = 'QUEUE_TO_USER_MAPPINGS',
    USER_GROUP_TO_USER_MAPPINGS = 'USER_GROUP_TO_USER_MAPPINGS',
    TRD_MAPPINGS = 'TRD_MAPPINGS',
    CASE_ENTITY = 'CASE_ENTITY'
}

export enum UserCreationTabs {
    MANUAL = "Manual",
    SEARCH = "Search"
};

export enum SearchFilter {
    EMPLOYEE_ID = "Employee ID",
    QUEUE_ID = "Queue ID",
    QUEUE_NAME = "Queue Name",
    PROFILE_ID = "Profile ID",
    FIELD_ID = "Field ID",
    USER_GROUP_ID = "User Group ID",
    DRIVER = "Contact Driver",
};

export enum ERROR_CODE {
    GENERIC_FAILURE = 'generic-failure',
};

export enum STAGE {
    ALPHA = 'ALPHA',
    BETA = 'BETA',
    PROD = 'PROD',
}

export const DOMAIN_TO_STAGE: Record<string, STAGE> = {
    'admin-console.alpha.cases.pxt.amazon.dev': STAGE.ALPHA,
    'admin-console.beta.cases.pxt.amazon.dev': STAGE.BETA,
    'admin-console.prod.cases.pxt.amazon.dev': STAGE.PROD,
};

export const DEFAULT_DOMAIN_TO_USECASE_MAP: Record<string, string> = {
    'OPSHR': 'HRSC-PANO-OpsHR',
    'MHLS': 'USCEmployeeResourceCentre',
    'CTK': 'USCCentralizedTimeKeepingRecordType',
    'HRP': 'HRSC-PANO-HRRegionalPartner',
    'BXT_SE': 'USC-BXTSE',
    'BALI': 'USCBALI',
    'DALI': 'USCDALI',
    'GWA': 'GlobalWorkAuthorization',
    'ATP': 'USCAnyTimePay',
    'BST': 'CompassionateCareService',
    'COE': 'HRSC-CenterOfExcellence',
    'EMP_APPEAL': 'HRSC-EmpAppeal',
    'MOBILITY': 'USCMobility'
};