import React, { createContext, useEffect, useState } from 'react';
import Amplify from '@aws-amplify/core';
import {Auth, CognitoUser} from '@aws-amplify/auth';
import { Cookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie/cjs/types';
import {
    LOGIN_VISIT_REDIRECT_PATH,
    USER_AUTHORIZATION_TOKEN_KEY,
    USER_SESSION_CACHED,
    USER_SESSION_REFRESHED,
    USER_SESSION_STATE_KEY,
} from 'src/constants/AccessConstants';
import { SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { browserHistory } from 'src/AppRoutes';
import { AuthenticatedUser } from 'src/models/AuthenticatedUser';
import ContentLoader from 'src/components/ContentLoader';
import Session  from 'src/components/Session';
import { amplifyConfig, cognitoUrl } from 'src/components/Authentication/Amplify';
import { logger } from "src/logger/KatalLoggerClient";

Amplify.configure(amplifyConfig);
const COOKIE_TTL = 300000000;

interface AuthenticatedRouteProps {
    children: React.ReactNode;
}

interface AuthenticatedCognitoUser extends CognitoUser {
    attributes: {
        preferred_username: string,
        given_name: string,
        family_name: string,
        'custom:EMPLOYEE_ID': string,
    }
    signInUserSession: {
        idToken: {
            jwtToken: string;
        }
    };


}
interface UserContextProps {
    authenticatedUser: AuthenticatedUser;
    setAuthenticatedUser: React.Dispatch<React.SetStateAction<AuthenticatedUser>>;
}

export const UserContext = createContext<UserContextProps>({} as UserContextProps);

/**
 * Authenticated Route component to render children after authenticating user via cognito-midway
 */
const Authentication = ({ children }: AuthenticatedRouteProps) => {
    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [authenticatedUser, setAuthenticatedUser] = useState<AuthenticatedUser>({} as AuthenticatedUser);

    const cookies = new Cookies();
    const cookieStorageOptions: CookieSetOptions = {
        path: '/',
        secure: true,
        sameSite: 'strict',
        domain: window.location.hostname,
        expires: new Date(Date.now() + COOKIE_TTL),
    };

    const onAuthenticationSuccess = (authenticatedUser: AuthenticatedCognitoUser) => {
        cookies.set(
            USER_AUTHORIZATION_TOKEN_KEY,
            authenticatedUser.signInUserSession.idToken.jwtToken,
            cookieStorageOptions,
        );
        const userDetails: AuthenticatedUser = {
            alias: authenticatedUser.attributes.preferred_username,
            firstName: authenticatedUser.attributes.given_name,
            lastName: authenticatedUser.attributes.family_name,
            employeeId: authenticatedUser.attributes['custom:EMPLOYEE_ID'],
        };
        setAuthenticatedUser(userDetails);
        setIsUserAuthenticated(true);
    };

    const redirectForAuthentication = () => {
        cookies.set(USER_SESSION_STATE_KEY, USER_SESSION_REFRESHED, cookieStorageOptions);
        cookies.set(LOGIN_VISIT_REDIRECT_PATH, `${location.pathname}${location.search}`, cookieStorageOptions);
        window.location.replace(cognitoUrl);
    };

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((authenticatedUser: AuthenticatedCognitoUser) => {
                if (cookies.get(USER_SESSION_STATE_KEY) === USER_SESSION_REFRESHED) {
                    cookies.set(USER_SESSION_STATE_KEY, USER_SESSION_CACHED, cookieStorageOptions);
                    browserHistory.replace(cookies.get(LOGIN_VISIT_REDIRECT_PATH));
                    onAuthenticationSuccess(authenticatedUser);
                    console.log('User Authenticated', authenticatedUser);
                } else if (cookies.get(USER_SESSION_STATE_KEY) === USER_SESSION_CACHED) {
                    onAuthenticationSuccess(authenticatedUser);
                } else {
                    console.log('Authentication failed', authenticatedUser);
                    redirectForAuthentication();
                }
            })
            .catch((error: any) => {
                logger.error('Error occurred during user authentication', error).then();
                redirectForAuthentication();
            });
    }, []);

    if (isUserAuthenticated) {
        if (Session.getCurrentSessionId() === undefined) {
            Session.createSessionIdAndStoreInCookies();
        } else {
            Session.setSessionRefresh();
        }
        return (
            <UserContext.Provider value={{ authenticatedUser, setAuthenticatedUser }}>
                <React.Fragment>{children}</React.Fragment>
            </UserContext.Provider>
        );
    } else {
        return <ContentLoader size={SpinnerSize.Large} loadingText={'Authenticating'} fontSize={'T400'} height={'95vh'} />;
    }
};

export default Authentication;