import React from 'react';
import { Col, Container, View } from '@amzn/stencil-react-components/layout';
import {IconHome, IconSize} from '@amzn/stencil-react-components/icons';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Text } from '@amzn/stencil-react-components/text';
import { FAILURE_ERROR_IMAGE_TEXT } from 'src/constants/DisplayConstants';
import { StringUtils } from 'src/utils/StringUtils';
import { usePageTitle } from 'src/hooks/usePageAsTitle';
import { getStageExternalURLs } from "src/utils/StageConfigUtils";
import './FailureErrorPage.scss';

export const DEFAULT_ERROR_TITLE = 'Aaah! Something went wrong';
export const DEFAULT_ERROR_BUTTON_MESSAGES = [
  'We keep track of these errors and try to make the user journey better.',
  'Thanks for your patience.',
];
export const DEFAULT_ERROR_BUTTON_TEXT = 'Go to Admin Console Home';
export const DEFAULT_ERROR_BUTTON_LINK = '/';


interface FailureErrorViewProps {
  title?: string;
  messages?: ReadonlyArray<string>;
  buttonText?: string;
  errorCode?: string;
  buttonOnClick?: () => void;
}

export const FailureErrorView = (props: FailureErrorViewProps) => {
  const defaultErrorButtonOnClick = () => {
    window.location.assign(DEFAULT_ERROR_BUTTON_LINK);
  };

  const {
    title = DEFAULT_ERROR_TITLE,
    messages = DEFAULT_ERROR_BUTTON_MESSAGES,
    buttonText = DEFAULT_ERROR_BUTTON_TEXT,
    errorCode,
    buttonOnClick = defaultErrorButtonOnClick,
  } = props;

  usePageTitle(title);

  return (
    <Col flexWrap={'wrap'} alignItems={'center'} justifyContent={'center'}>
      <View className={'failure-error-image-wrapper'}>
        <Container height={'52rem'} width={'5rem'} paddingTop={'1rem'} paddingHorizontal={'20rem'}>
          <img
            className="failure-error-image"
            src={getStageExternalURLs().INTERNAL_SERVER_ERROR_IMAGE}
            alt={FAILURE_ERROR_IMAGE_TEXT}
          />
        </Container>
      </View>
      <View className={'failure-error-text-wrapper'}>
        <View padding={{ bottom: 10 }}>
          <Text fontSize="T800" fontWeight="regular" textAlign="center">
            {title}
          </Text>
        </View>
        <View padding={{ bottom: 10 }}>
          {messages.map((message) => (
            <Text key={StringUtils.generateStringHash(message)} fontSize="T500" fontWeight="regular" textAlign="center">
              {message}
            </Text>
          ))}
        </View>
        <Col padding={{ top: 20, bottom: 20 }} alignItems={'center'}>
          <Button variant={ButtonVariant.Primary} onClick={buttonOnClick} icon={<IconHome aria-hidden={true} size = {IconSize.Medium}/>}>
            <Text fontSize="T500" fontWeight="regular" textAlign="center">
              {buttonText}
            </Text>
          </Button>
        </Col>
      </View>
    </Col>
  );
};
