import {
    CreateOrUpdateQueuesFormData,
    CreateOrUpdateQueuesRequest, QueueUserOperationType
} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import {
    CreateOrUpdateQueuesResponse,
} from "src/models/ServiceResponses/CaseAuthorityServiceResponses";
import AsyncRequestHandler from "src/utils/HttpUtils/HttpHandler";
import {CaseAuthorityService} from "src/service/CaseAuthorityService";
import {ExceptionDetail} from "src/models/ExceptionDetail";
import {QueueUserOperation} from "src/models/Queue";
import {MAX_USER_IDS_LENGTH} from "src/constants/ServiceConstants";
import {Utils} from "src/utils/Utils";
import { logger } from "src/logger/KatalLoggerClient";

const createQueue = async (domainId: string, createdBy: string, queueDetails: CreateOrUpdateQueuesFormData) => {
    try {
        const createQueueRequest = buildCreateQueueRequest(domainId, createdBy, queueDetails);
        const response = await AsyncRequestHandler(CaseAuthorityService.createOrUpdateQueue(createQueueRequest));
        return buildCreateOrEditQueuesResponse(response);
    } catch (error) {
        logger.error('Create Queue call failed', error.message).then();
        throw buildCreateOrUpdateQueuesException(error);
    }
}

const editQueue = async (domainId: string, updatedBy: string, queueDetails: CreateOrUpdateQueuesFormData)  => {
    try {
        const editQueueRequest = buildEditQueuesRequest(domainId, updatedBy, queueDetails);
        const response = await AsyncRequestHandler(CaseAuthorityService.createOrUpdateQueue(editQueueRequest));
        return buildCreateOrEditQueuesResponse(response);
    } catch (error) {
        logger.error('Edit Queue call failed', error.message).then();
        throw buildCreateOrUpdateQueuesException(error);
    }
}
const buildCreateQueueRequest = (domainId: string, createdBy: string, queueDetails: CreateOrUpdateQueuesFormData): CreateOrUpdateQueuesRequest => {
    return {
        domainId: domainId,
        queueId: queueDetails.queueId,
        name: queueDetails.name,
        additionalAttributes: queueDetails.additionalAttributes ? queueDetails.additionalAttributes : JSON.stringify({}),
        description: queueDetails.description,
        createdBy: createdBy,
        lastUpdatedBy: createdBy,
        userOperation: queueDetails.userOperation,
    }
}

const buildEditQueuesRequest = (domainId: string, updatedBy: string, queueDetails: CreateOrUpdateQueuesFormData): CreateOrUpdateQueuesRequest => {
    return {
        domainId: domainId,
        queueId: queueDetails.queueId,
        name: queueDetails.name ,
        additionalAttributes: queueDetails.additionalAttributes ? queueDetails.additionalAttributes : JSON.stringify({}),
        description: queueDetails.description,
        lastUpdatedBy: updatedBy,
        userOperation: queueDetails.userOperation
    }
}

const buildCreateOrEditQueuesResponse = (response): CreateOrUpdateQueuesResponse => {
    return {
        ...response,
    } as CreateOrUpdateQueuesResponse;
}

const buildCreateOrUpdateQueuesException = (error): ExceptionDetail => {
    return {
        errorCode: error.response.data.errorCode,
        errorStatus: error.response.status,
        errorMessage: error.response.data.message,
        retryable: error.retryable,
    } as ExceptionDetail;
};


const CreateOrUpdateQueuesBuilder = {
    createQueue, editQueue
};

export default CreateOrUpdateQueuesBuilder;