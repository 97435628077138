import AsyncRequestHandler from "src/utils/HttpUtils/HttpHandler";
import {CaseAuthorityService} from "src/service/CaseAuthorityService";
import {GetUserDetailsResponse} from "src/models/ServiceResponses/CaseAuthorityServiceResponses";
import {buildCaseAuthorityException} from "src/builders/CaseAuthorityCommonsBuilder";
import { logger } from "src/logger/KatalLoggerClient";
import {GLOBAL} from "src/constants/DisplayConstants";

const getUserDetails = async (domainId: string, employeeId?: string, userId?: string) => {
	try {
		let getUsersRequest
		if (domainId === GLOBAL) {
			getUsersRequest = buildGetUsersDetailsRequest(domainId, employeeId, userId, true);
		} else {
			getUsersRequest = buildGetUsersDetailsRequest(domainId, employeeId, userId);
		}
		const response = await AsyncRequestHandler(CaseAuthorityService.getUserDetails(getUsersRequest));
		return buildGetUserDetailsResponse(response);
	} catch (error) {
		logger.error('Fetch User Details call failed', error.message).then();
		throw buildCaseAuthorityException(error);
	}
};

const buildGetUsersDetailsRequest = (domainId: string, employeeId?: string, userId?: string, isAdmin?: boolean) => {
	return {domainId: domainId, employeeId: employeeId, userId: userId, isAdmin: isAdmin};
}

const buildGetUserDetailsResponse = (response): GetUserDetailsResponse => {
	return response as GetUserDetailsResponse;
}

const GetUserDetailsBuilder = {
	getUserDetails
};

export default GetUserDetailsBuilder;