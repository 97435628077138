import React from 'react';
import {
    SideNav,
    SideNavItem,
    SideNavSection
} from '@amzn/stencil-react-components/side-nav';
import {Frame} from "@amzn/stencil-react-components/frame";
import {Link, useLocation, useParams} from "react-router-dom";
import {UrlUtils} from "src/utils/UrlUtils";
import {PAGE_PATHS} from "src/constants/Urls";
import { isTRDEnabledDomain } from 'src/pages/TRD/TRDUtils';

const SideNavItemLink = SideNavItem.withComponent(Link);
const LeftNavigationPanel = () => {
    const location = useLocation();
    const { domainId } : { domainId: string }  = useParams();
    const usersPageLocation = UrlUtils.prepareUrl(PAGE_PATHS.USERS, domainId!);
    const userProfilesPageLocation = UrlUtils.prepareUrl(PAGE_PATHS.PROFILES, domainId!);
    const caseQueuesPageLocation = UrlUtils.prepareUrl(PAGE_PATHS.CASE_QUEUES, domainId!);
    const userGroupPageLocation = UrlUtils.prepareUrl(PAGE_PATHS.USER_GROUP, domainId);
    const trdPageLocation = UrlUtils.prepareUrl(PAGE_PATHS.TRD, domainId);
    const bulkCaseManagementLocation = UrlUtils.prepareUrl(PAGE_PATHS.BULK_CASE_MANAGEMENT, domainId)
    const isTRDEnabled = isTRDEnabledDomain(domainId);

    return (
        <Frame
            height={isTRDEnabled ? 320 : 280}
            titleText="">
            <SideNav>
                <SideNavSection label="Admin Entities">
                    <SideNavItemLink to={usersPageLocation} isSelected={location.pathname === usersPageLocation}>Users</SideNavItemLink>
                    <SideNavItemLink to={userProfilesPageLocation} isSelected={location.pathname === userProfilesPageLocation}>User Profiles</SideNavItemLink>
                    <SideNavItemLink to={caseQueuesPageLocation} isSelected={location.pathname === caseQueuesPageLocation}>Case Queues</SideNavItemLink>
                    <SideNavItemLink to={userGroupPageLocation
                    } isSelected={location.pathname === userGroupPageLocation}>User Groups</SideNavItemLink>
                    {isTRDEnabled && <SideNavItemLink to={trdPageLocation} isSelected={location.pathname === trdPageLocation}>TRD</SideNavItemLink>}
                    <SideNavItemLink to={bulkCaseManagementLocation} isSelected={location.pathname === bulkCaseManagementLocation}>Bulk Case Management</SideNavItemLink>
                </SideNavSection>
            </SideNav>
        </Frame>
    )
}

export default LeftNavigationPanel