/* eslint-disable */
import {
    CreateOrUpdateUserGroupsFormData, CreateOrUpdateUserGroupsRequest
} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import {CreateOrUpdateUserGroupsResponse} from "src/models/ServiceResponses/CaseAuthorityServiceResponses";
import AsyncRequestHandler from "src/utils/HttpUtils/HttpHandler";
import {CaseAuthorityService} from "src/service/CaseAuthorityService";
import {ExceptionDetail} from "src/models/ExceptionDetail";

const createUserGroup = async (domainId: string, createdBy: string, userGroupDetails: CreateOrUpdateUserGroupsFormData) => {
    try {
        const createUserGroupRequest = buildCreateUserGroupRequest(domainId, createdBy, userGroupDetails);
        const response = await AsyncRequestHandler(CaseAuthorityService.createOrUpdateUserGroup(createUserGroupRequest));
        return buildCreateOrEditUserGroupResponse(response);
    } catch (error) {
        console.error(error.message)
        throw buildCreateOrUpdateUserGroupException(error);
    }
}

const editUserGroup = async (domainId: string, updatedBy: string, userGroupDetails: CreateOrUpdateUserGroupsFormData)  => {
    try {
        const editUserGroupRequest = buildEditUserGroupRequest(domainId, updatedBy, userGroupDetails);
        const response = await AsyncRequestHandler(CaseAuthorityService.createOrUpdateUserGroup(editUserGroupRequest));
        return buildCreateOrEditUserGroupResponse(response);
    } catch (error) {
        console.error(error.message)
        throw buildCreateOrUpdateUserGroupException(error);
    }
}
const buildCreateUserGroupRequest = (domainId: string, createdBy: string, userGroupDetails: CreateOrUpdateUserGroupsFormData): CreateOrUpdateUserGroupsRequest => {
    return {
        domainId: domainId,
        name: userGroupDetails.name,
        additionalAttributes: userGroupDetails.additionalAttributes ? userGroupDetails.additionalAttributes : JSON.stringify({}),
        description: userGroupDetails.description,
        createdBy: createdBy,
        lastUpdatedBy: createdBy,
    }
}

const buildEditUserGroupRequest = (domainId: string, updatedBy: string, userGroupDetails: CreateOrUpdateUserGroupsFormData): CreateOrUpdateUserGroupsRequest => {
    return {
        domainId: domainId,
        userGroupId: userGroupDetails.userGroupId,
        name: userGroupDetails.name ,
        additionalAttributes: userGroupDetails.additionalAttributes ? userGroupDetails.additionalAttributes : JSON.stringify({}),
        description: userGroupDetails.description,
        lastUpdatedBy: updatedBy,
        userGroupOperation: userGroupDetails.userOperation
    }
}

const buildCreateOrEditUserGroupResponse = (response): CreateOrUpdateUserGroupsResponse => {
    return {
        ...response,
    } as CreateOrUpdateUserGroupsResponse;
}

const buildCreateOrUpdateUserGroupException = (error): ExceptionDetail => {
    return {
        errorCode: error.response.data.errorCode,
        errorStatus: error.response.status,
        errorMessage: error.message,
        retryable: error.retryable,
    } as ExceptionDetail;
};


const CreateOrUpdateUserGroupsBuilder = {
    createUserGroup, editUserGroup
};

export default CreateOrUpdateUserGroupsBuilder;