import React from 'react';
import { browserHistory } from 'src/AppRoutes';
import { PAGE_PATHS } from "src/constants/Urls";
import { ERROR_CODE } from "src/constants/enums";
interface AppErrorBoundaryProps {
    children: React.ReactNode;
}

interface AppErrorBoundaryState {
    hasError: boolean;
}

class AppErrorBoundary extends React.Component<AppErrorBoundaryProps, AppErrorBoundaryState> {
    readonly UNHANDLED_REJECTION = 'unhandledrejection';
    constructor(props: AppErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    promiseRejectionHandler = (event: PromiseRejectionEvent) => {
        event.preventDefault();
        console.error('Exception with reason ', event.reason, undefined, false);
        this.setState({ hasError: true });
        browserHistory.replace(PAGE_PATHS.FAILURE_PAGE + ERROR_CODE.GENERIC_FAILURE);
    };

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        console.error('Exception with stack trace ' + info.componentStack, error, undefined, false);
        this.setState({ hasError: true });
        browserHistory.replace(PAGE_PATHS.FAILURE_PAGE + ERROR_CODE.GENERIC_FAILURE);
    }

    componentDidMount() {
        window.addEventListener(this.UNHANDLED_REJECTION, this.promiseRejectionHandler);
    }

    componentWillUnmount() {
        window.removeEventListener(this.UNHANDLED_REJECTION, this.promiseRejectionHandler);
    }

    render() {
        return <>{this.props.children}</>;
    }
}

export default AppErrorBoundary;