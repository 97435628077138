/* eslint-disable */
import {GetUserGroupsRequest} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import {DEFAULT_REQUEST_PAGE_SIZE} from "src/constants/DisplayConstants";
import AsyncRequestHandler from "src/utils/HttpUtils/HttpHandler";
import {CaseAuthorityService} from "src/service/CaseAuthorityService";
import {GetUserGroupsResponse} from "src/models/ServiceResponses/CaseAuthorityServiceResponses";
import {ExceptionDetail} from "src/models/ExceptionDetail";
import { logger } from "src/logger/KatalLoggerClient";

const getUserGroups = async (domainId: string, userId?: string, userGroupId?: string) => {
    try {
        const getUserGroupsRequest = buildGetUserGroupsRequest(domainId!, DEFAULT_REQUEST_PAGE_SIZE, userId, userGroupId);
        const response = await AsyncRequestHandler(CaseAuthorityService.getUserGroups(getUserGroupsRequest));
        return buildGetUserGroupsResponse(response);
    } catch (error) {
        logger.error('Fetch User Groups call failed', error.message).then();
        throw buildGetUserGroupsException(error);
    }
};
const buildGetUserGroupsRequest = (domainId: string, pageSize: number, userId?: string, userGroupId?:string): GetUserGroupsRequest =>  {
    if(userId) {
        return {domainId: domainId, pageSize: pageSize, userId: userId};
    } else {
        return {domainId: domainId, pageSize: pageSize, userId: undefined, userGroupId: userGroupId};
    }
}

const buildGetUserGroupsResponse = (response): GetUserGroupsResponse => {
    return response as GetUserGroupsResponse;
}

const buildGetUserGroupsException = (error): ExceptionDetail => {
    return {
        errorCode: error.response.data.errorCode,
        errorStatus: error.response.status,
        errorMessage: error.message,
        retryable: error.retryable,
    } as ExceptionDetail;
};


const GetUserGroupsBuilder = {
    getUserGroups
};

export default GetUserGroupsBuilder;