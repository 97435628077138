import { PXT_CMS_DOMAIN_ID_TO_USC_DOMAIN_MAPPING } from "src/constants/PxtCmsDomainToUSCMapping";

/**
 * Returns domain name to be displayed in UI
 * @param pxtCmsDomainId domain-id in PXT CMS
 * @returns USC domain name if available in PXT_CMS_DOMAIN_ID_TO_USC_DOMAIN_MAPPING constant otherwise pxtCmsDomainId
 */
export const getDisplayDomainName = (pxtCmsDomainId: string) => {
  return (
    PXT_CMS_DOMAIN_ID_TO_USC_DOMAIN_MAPPING[pxtCmsDomainId] ?? pxtCmsDomainId
  );
};
