export const ALLOW_LIST = new Set<string>([
    '113185252', //syichen
    '100640251', //jalanh
    '104862219', //punsai
    '101954021', //gakansha
    '107256437', //salekya
    '111989027', //murpriti
    '113096103', //surapars
    '107257632', //bajshyam
    '113203110', //ritikdx
    '494059', //shrvikas
    '106521917', //surchur
    '112662699', //abhvarsh
    '106051432', //pratgp
    '112756720', //sathvikt
    '101892733', //sadiqmk
]);