import React, {useEffect, useState} from "react";
import { Checkbox } from "@amzn/stencil-react-components/form";
import {
    Table,
    TableProps,
} from "@amzn/stencil-react-components/table";
import {RequiredFields} from "src/models/RequiredFields";
import {Pagination} from "@amzn/stencil-react-components/pagination";
import {DEFAULT_DISPLAY_PAGE_SIZE, INITIAL_PAGE_NUMBER} from "src/constants/DisplayConstants";
import {Button, ButtonVariant} from "@amzn/stencil-react-components/button";
import {Col, Flex, Row} from "@amzn/stencil-react-components/layout";
import { isTRDEnabledDomain } from "src/pages/TRD/TRDUtils";

interface EntityTableProps extends Partial<TableProps> {
    data: any; // will be handled while API integration
    domainId: string;
    requiredFields: Array<RequiredFields>;
    postfixColumns?: Array<RequiredFields>;
    onTableReload?: Function;
    EditEntityModal?: Function;
    customActionColumn?: Array<RequiredFields>;
    isBulkUpdateEnabled?: boolean;
    setMessageBannerDetails?: Function;
    flipBannerVisibility?: Function;
    editButtonName?: string;
    enableActionColumn?: boolean;
}

const EntityTable = ({
                         maxHeight,
                         hasStickyHeader,
                         spacing,
                         data,
                         domainId,
                         requiredFields,
                         postfixColumns,
                         onTableReload,
                         EditEntityModal,
                         setMessageBannerDetails,
                         flipBannerVisibility,
                         customActionColumn,
                         isBulkUpdateEnabled,
                         editButtonName = 'Edit',
                         enableActionColumn = true,
                     }: EntityTableProps) => {
    const [selectedPage, setSelectedPage] = useState<number>(INITIAL_PAGE_NUMBER);
    const [editModalData, setEditModalData] = useState({});
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [bulkEditModalIsOpen, setBulkEditModalIsOpen] = useState(false);
    const [selectBulkUpdateDisabled, setSelectBulkUpdateDisabled] = useState(true);


    const sliceDataByPage = (page: number) => {
        if (data) {
            return data.slice(
                (page - 1) * DEFAULT_DISPLAY_PAGE_SIZE,
                page * DEFAULT_DISPLAY_PAGE_SIZE,
            )
        }
    }
    const [displayedData, setDisplayedData] = useState(sliceDataByPage(INITIAL_PAGE_NUMBER));
    const [selectedData, setSelectedData] = useState<number[]>([]);
    const isTRDEnabled = isTRDEnabledDomain(domainId);

    useEffect(() => {
        setDisplayedData(sliceDataByPage(INITIAL_PAGE_NUMBER));
    }, [data]);

    const handleSelectRow = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        let newSelectedData: number[] = [...selectedData];

        if (e.target.checked) {
            newSelectedData.push(displayedData[index])
            setSelectBulkUpdateDisabled(false);
        } else {
            newSelectedData = newSelectedData.filter((data) => data != displayedData[index]);
            newSelectedData.length == 0 ? setSelectBulkUpdateDisabled(true) :
                setSelectBulkUpdateDisabled(false)
        }
        setSelectedData(newSelectedData);
    };

    const isAllDataSelected = data && selectedData.length === displayedData.length;

    const handleSelectRowAll = () => {

        let newSelectedData: number[] = [];
        if (!isAllDataSelected) {
            newSelectedData = displayedData.map((item: string) => item);
            setSelectBulkUpdateDisabled(false);
        } else {
            setSelectBulkUpdateDisabled(true);
        }
        setSelectedData(newSelectedData);
    };

    const defaultColumn = [
        {
            cellComponent: ({ index }: { index: number }) => (
                <Checkbox
                    aria-labelledby={`name_${index}`}
                    checked={selectedData.indexOf(displayedData[index]) > -1}
                    onChange={(e) => handleSelectRow(e, index)}
                />
            ),
            header: (
                <Checkbox
                    aria-label="Select all the rows"
                    checked={isAllDataSelected}
                    onChange={handleSelectRowAll}
                />
            ),
        },
    ];

    const actionColumn = customActionColumn ? customActionColumn : [
        {
            cellComponent: ({ index }: { index: number }) => (
                <Button
                    aria-describedby="sroDialog"
                    variant={ButtonVariant.Tertiary}
                    onClick={ (e) => {
                        setEditModalIsOpen(true);
                        setEditModalData(isBulkUpdateEnabled ? [displayedData[index]] : {...displayedData[index]});
                    }}
                >
                    {editButtonName}
                </Button>
            ),
            header: 'Action'
        }
    ];

    let columns = [...defaultColumn, ...requiredFields, ...(enableActionColumn ? actionColumn : []), ...(postfixColumns ? postfixColumns : [])];

    return (
        <>
            {isBulkUpdateEnabled && <Row justifyContent="flex">
                <Button
                    aria-describedby="sroDialog"
                    variant={ButtonVariant.Tertiary}
                    onClick={(e) => {
                        setBulkEditModalIsOpen(true);
                    }}
                    disabled={selectBulkUpdateDisabled}
                >
                    {editButtonName}
                </Button>
            </Row>
            }
            <Col gridGap="S200">
                <Table
                    maxHeight={maxHeight}
                    hasStickyHeader={hasStickyHeader}
                    spacing={spacing}
                    columns={columns}
                    data={displayedData}
                />
            </Col>
            {data && data.length > 0 ? (
                <Flex flexDirection={'row'} justifyContent={'center'} margin={'3rem 0 0 0'}>
                    <Pagination
                        aria-labelledby={'entity-table-pagination'}
                        numberOfPages={Math.ceil(data.length / DEFAULT_DISPLAY_PAGE_SIZE)}
                        onPageSelect={(page) => {
                            setSelectedPage(page);
                            setDisplayedData(sliceDataByPage(page));
                        }}
                        selectedPage={selectedPage}
                    />
                </Flex>
            ) : null}
            { EditEntityModal && editModalIsOpen ?
                <EditEntityModal domainId={domainId} data={editModalData} onClose={() =>
                    setEditModalIsOpen(false)} onTableReload={onTableReload}
                    setMessageBannerDetails={setMessageBannerDetails} flipBannerVisibility={flipBannerVisibility}
                />
                : null }
            { EditEntityModal && bulkEditModalIsOpen ?
                <EditEntityModal domainId={domainId} data={selectedData} onClose={() =>
                    setBulkEditModalIsOpen(false)} onTableReload={onTableReload}
                    setMessageBannerDetails={setMessageBannerDetails} flipBannerVisibility={flipBannerVisibility}
                />
                : null }
        </>
    );
};
export default EntityTable;