/* eslint-disable */
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { ModalContent, WithModal } from "@amzn/stencil-react-components/modal";
import React, {useContext, useState} from "react";
import {
    FormWrapper,
    Input
} from "@amzn/stencil-react-components/form";
import {UserContext} from "src/components/Authentication/Authentication";
import {CreateEntityModalInputElements} from "src/configurations/InputElementConfigs";
import {ENTITY_TYPE} from "src/constants/enums";
import {  Flex } from "@amzn/stencil-react-components/layout";
import { IconMinus, IconPlus } from "@amzn/stencil-react-components/icons";
import { Label } from "@amzn/stencil-react-components/text";
import {
    CreateOrUpdateUserGroupsFormData
} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import CreateOrUpdateUserGroupsBuilder from "src/builders/CreateOrUpdateUserGroups";

const initialAttributes = {key: "", value: ""};
interface AdditionalAttributes {
    key: string;
    value: string;
}

const CreateUserGroupModal = ({domainId, onTableReload}: {domainId: string, onTableReload: Function}) => {
    const userContext = useContext(UserContext).authenticatedUser;
    let [userGroupDetails, setUserGroupDetails] = useState<CreateOrUpdateUserGroupsFormData>({});
    const [additionalAttribute, setAdditionalAttribute]  = useState<AdditionalAttributes[]>([initialAttributes]);

    const createEntity = async (close) => {
        try {
            let additionalAttributeDetails = buildAdditionalAttributes(additionalAttribute);
            const userGroupRequest : CreateOrUpdateUserGroupsFormData = {...userGroupDetails,
                additionalAttributes: JSON.stringify(additionalAttributeDetails)
            }
            console.log('user group request' + userGroupRequest);
            const createUserGroupsResponse = await CreateOrUpdateUserGroupsBuilder.createUserGroup(domainId, userContext.alias, userGroupRequest);
            onTableReload(domainId);
        } catch (error) {
            console.error(error.message);
        }
        close();
    };

    // additional attributes methods
    const handleChangeInput = (index, event) => {
        const newInputFields = additionalAttribute.map((attribute, attributeIndex) => {
            attribute[event.target.name] = event.target.value;
            return attribute;
        });
        setAdditionalAttribute(newInputFields);
    };

    const handleAddFields = () => {
        setAdditionalAttribute([...additionalAttribute, initialAttributes]);
    };

    const handleRemoveFields = (index) => {
        const values = [...additionalAttribute];
        values.splice(index, 1);
        setAdditionalAttribute(values);
    };

    const buildAdditionalAttributes = (additionalAttribute) => {
        let additionalAttributeDetails: Record<string, string> = {};
        additionalAttribute.forEach(function (attribute) {
            if((attribute.key !== '' && attribute.value !== ''))
                additionalAttributeDetails[attribute.key] = attribute.value;
        });
        return additionalAttributeDetails;
    }
    const renderModal = ({close}) => (
        <ModalContent
            maxWidth="90vw"
            titleText={`Create new UserGroup`}
            buttons={[
                <Button onClick={() => createEntity(close)} variant={ButtonVariant.Primary}>
                    Create
                </Button>,
            ]}
        >
            <FormWrapper width={600}>
                {CreateEntityModalInputElements[ENTITY_TYPE.USER_GROUPS].map((entity) => (
                    <>
                        <Label>{entity.labelText}</Label>
                        <Input
                            id={entity.id}
                            onChange={(event) => {
                                userGroupDetails[entity.id] = event.target.value;
                            }}
                        />
                    </>
                ))}

                <Label>Additional Attributes</Label>
                {additionalAttribute.map((attribute, index) => (
                    <Flex
                        key={index}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                    >
                        <Flex flexDirection={"row"}>
                            <Input
                                name="key"
                                value={attribute.key}
                                onChange={(event) =>
                                    handleChangeInput(index, event)
                                }
                            />
                            <Input
                                name="value"
                                value={attribute.value}
                                onChange={(event) =>
                                    handleChangeInput(index, event)
                                }
                            />
                        </Flex>
                        <Flex flexDirection={"row"}>
                            <Button
                                icon={<IconMinus />}
                                disabled={additionalAttribute.length === 1}
                                onClick={() => handleRemoveFields(index)}
                            ></Button>
                            <Button
                                icon={<IconPlus />}
                                onClick={handleAddFields}
                            ></Button>
                        </Flex>
                    </Flex>
                ))}
            </FormWrapper>
        </ModalContent>
    );
    return (
        <WithModal renderModal={renderModal}>
            {({ open }) => (
                <Button
                    variant={ButtonVariant.Tertiary}
                    onClick={open}
                >
                    Create
                </Button>
            )}
        </WithModal>
    );
};

export default CreateUserGroupModal;