import React, {useContext, useEffect, useState} from 'react';
import {Col, Row} from "@amzn/stencil-react-components/layout";
import LeftNavigationPanel from "src/components/LeftNavigationPanel";
import {H1} from "@amzn/stencil-react-components/text";
import {useParams} from "react-router-dom";
import {useAsyncState} from "src/hooks/useAsyncState";
import ContentLoader from "src/components/ContentLoader";
import {SpinnerSize} from "@amzn/stencil-react-components/spinner";
import TableHeader from "src/components/TableHeader";
import EntityTable from "src/components/EntityTable";
import {StencilProvider} from "@amzn/stencil-react-components/context";
import {TableRequiredFields} from "src/configurations/InputElementConfigs";
import {ENTITY_TYPE, SearchFilter} from "src/constants/enums";
import {UserContext} from "src/components/Authentication/Authentication";
import GetUserGroupsBuilder from "src/builders/GetUserGroupsBuilder";
import {UserGroup} from "src/models/UserGroup";
import CreateUserGroupModal from "src/pages/UserGroups/CreateUserGroupsModal";
import EditUserGroupsModal from "src/pages/UserGroups/EditUserGroupsModal";
import {UrlUtils} from "src/utils/UrlUtils";
import {PAGE_PATHS} from "src/constants/Urls";
import {Button, ButtonVariant} from "@amzn/stencil-react-components/button";
import {IconChevronRight} from "@amzn/stencil-react-components/icons";
import {browserHistory} from "src/AppRoutes";
import {ListOfDropdownActions} from "src/components/DropdownActions";
import {
    ADD_USER_TO_GROUP_BULK_PAGE_NAME,
    ADD_USERS_TO_GROUPS,
    BULK_DROPDOWN_NAME,
    BULK_PROCESS_USER_GROUP_SUCCESS_MESSAGE,
    CREATE, CREATE_USER_GROUP_BULK_PAGE_NAME,
    GROUP_TEMPLATE_FILE_NAME,
    MessageBannerDetails, REMOVE_USER_TO_GROUP_BULK_PAGE_NAME,
    REMOVE_USERS_FROM_GROUPS,
    SUCCESS_MSG_BANNER_AUTO_DISMISS_TIME,
    UPDATE, UPDATE_USER_GROUP_BULK_PAGE_NAME,
    USER_GROUP_BULK_PAGE_NAME
} from "src/constants/DisplayConstants";
import AutoDismissMessageBanner from "src/components/AutoDismissMessageBanner";
import {UserGroupOperationType} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import {
    processBulkGroupToUserMapping,
    processBulkUserGroup,
} from "src/pages/UserGroups/UserGroupsUpdateProcessingUtils";
import {BulkActionPage, BulkActionType} from "src/pages/BulkActionPage";
import {processBulkQueues} from "src/pages/CaseQueues/QueueUpdateProcessingUtils";
import {getDisplayDomainName} from "src/utils/domain";


const UserGroups = () => {
    const { domainId } : { domainId: string }  = useParams();
    const displayDomainName = getDisplayDomainName(domainId);
    const userContext = useContext(UserContext).authenticatedUser;
    const userGroups = useAsyncState<UserGroup[]>();
    const [messageBannerDetails, setMessageBannerDetails] = useState<MessageBannerDetails>({});
    const [messageBannerVisible, setMessageBannerVisible] = useState(false);
    const [showUserGroupsTable, setShowUserGroupsTable] = useState(true);
    const [showBulkPage, setShowBulkPage] = useState(false);
    const [bulkActionType, setBulkActionType] = useState(BulkActionType.DEFAULT);
    const [bulkPageName, setBulkPageName] = useState(USER_GROUP_BULK_PAGE_NAME);
    const [entityType, setEntityType] = useState(ENTITY_TYPE.USER_GROUPS);

    const dropdownActionList : ListOfDropdownActions[] = [
        { actionName : CREATE, executeAction: () => {
                setBulkPageName(CREATE_USER_GROUP_BULK_PAGE_NAME);
                setEntityType(ENTITY_TYPE.USER_GROUPS);
                showBulkPageForActionType(BulkActionType.CREATE);}
        },
        { actionName : UPDATE, executeAction: () => {
                setBulkPageName(UPDATE_USER_GROUP_BULK_PAGE_NAME);
                setEntityType(ENTITY_TYPE.USER_GROUPS);
                showBulkPageForActionType(BulkActionType.UPDATE);}
        },
        { actionName : ADD_USERS_TO_GROUPS, executeAction: () => {
                setBulkPageName(ADD_USER_TO_GROUP_BULK_PAGE_NAME);
                setEntityType(ENTITY_TYPE.USER_GROUP_TO_USER_MAPPINGS);
                showBulkPageForActionType(BulkActionType.ADD_USERS_TO_GROUP);}
        },
        { actionName : REMOVE_USERS_FROM_GROUPS, executeAction: () => {
                setBulkPageName(REMOVE_USER_TO_GROUP_BULK_PAGE_NAME);
                setEntityType(ENTITY_TYPE.USER_GROUP_TO_USER_MAPPINGS);
                showBulkPageForActionType(BulkActionType.REMOVE_USERS_FROM_GROUP);}
        }
    ]

    const showBulkPageForActionType = (bulkActionType: BulkActionType) => {
        setBulkActionType(bulkActionType);
        setShowBulkPage(true)
        setShowUserGroupsTable(false);
    }

    const onBulkPageClose = () => {
        setShowBulkPage(false);
        setShowUserGroupsTable(true);
    }

    const onBulkPageSave = (records, actionType: BulkActionType) => {
        onBulkPageClose();
        switch (actionType) {
            case BulkActionType.CREATE:
                processBulkUserGroup(domainId, userContext.alias, records).then();
                break;
            case BulkActionType.UPDATE:
                processBulkUserGroup(domainId, userContext.alias, records).then();
                break;
            case BulkActionType.ADD_USERS_TO_GROUP:
                processBulkGroupToUserMapping(domainId, userContext.alias, UserGroupOperationType.ADD, records).then();
                break;
            case BulkActionType.REMOVE_USERS_FROM_GROUP:
                processBulkGroupToUserMapping(domainId, userContext.alias, UserGroupOperationType.REMOVE, records).then();
                break;
        }
        messageBannerDetails.messageBannerText = BULK_PROCESS_USER_GROUP_SUCCESS_MESSAGE;
        messageBannerDetails.messageBannerAutoDismiss = SUCCESS_MSG_BANNER_AUTO_DISMISS_TIME;
        flipVisibility();
    }

    const flipVisibility = () => {
        setMessageBannerVisible(!messageBannerVisible);
    }

    const getUserGroups = async (userGroupId?: string) => {
        try {
            userGroups.initiateAsyncState();
            const getUserGroupsResponse = await GetUserGroupsBuilder.getUserGroups(domainId, undefined, userGroupId);
            userGroups.setAsyncSuccess(getUserGroupsResponse.userGroups);
        } catch (error) {
            userGroups.setAsyncFailure(error);
        }
    };


    const getUserGroupsWithSearchFilter = async (filterName, filterValue) => {
        if (filterName == SearchFilter.USER_GROUP_ID) {
            await getUserGroups(filterValue);
        }
    }

    useEffect(() => {
        getUserGroups().then()
    }, []);

    if (userGroups.isLoading) {
        return <ContentLoader size={SpinnerSize.Large} loadingText={'Loading Groups'} fontSize={'T300'} />
    }

    const ViewUsersInUserGroupButtonCell = ({data}) => {
        const userGroupView = UrlUtils.prepareUrl(PAGE_PATHS.USER_GROUP_VIEW_WITH_USER_MANAGEMENT, domainId, data.userGroupId);
        return <Button icon={<IconChevronRight/>} variant={ButtonVariant.Tertiary} onClick={() => {browserHistory.push(userGroupView)}}/>
    }

    const viewUsersColumn = [{ header: "Users", cellComponent: ViewUsersInUserGroupButtonCell}]
    const requiredFields = [...TableRequiredFields[ENTITY_TYPE.USER_GROUPS]];
    const postfixColumns = [...viewUsersColumn]



    return (
        <>
            {messageBannerVisible? <AutoDismissMessageBanner
                bannerText={messageBannerDetails.messageBannerText}
                bannerType={messageBannerDetails.messageBannerType}
                bannerAutoDismissTime={messageBannerDetails.messageBannerAutoDismiss}
                flipVisibility={flipVisibility}
            /> : null}
            <Row width={'100%'} justifyContent={'center'} flexWrap={'wrap'}>
                <H1>{displayDomainName} UserGroups</H1>
            </Row>
            <Row>
                <LeftNavigationPanel />
                <Col width={'80%'} margin={'30px'}>
                    <StencilProvider>
                        <TableHeader entityName={ENTITY_TYPE.USER_GROUPS}
                                     CustomCreateModal={<CreateUserGroupModal
                                     domainId={domainId}
                                     onTableReload={getUserGroups} />}
                                     dropdownActions={{ listOfActions: dropdownActionList,
                                         csvExportEnabled: true,
                                         fileData: userGroups,
                                         fileName: 'User Groups List',
                                         dropDownName: BULK_DROPDOWN_NAME
                                     }}
                                     onTableReload={getUserGroups}
                                     searchByFilter={getUserGroupsWithSearchFilter}/>
                        {showBulkPage ?
                            <BulkActionPage
                                bulkActionName={bulkPageName}
                                domainId={domainId}
                                templateFileName={GROUP_TEMPLATE_FILE_NAME}
                                entityType={entityType}
                                onClose={onBulkPageClose}
                                onSave={onBulkPageSave}
                                bulkActionType={bulkActionType}/>
                            : null}
                        { showUserGroupsTable ?
                            <EntityTable data={userGroups.data != undefined ? userGroups.data : []}
                                         EditEntityModal={EditUserGroupsModal}
                                         requiredFields={requiredFields}
                                         postfixColumns={postfixColumns}
                                         domainId={domainId} onTableReload={getUserGroups}/>
                            : null }
                    </StencilProvider>
                </Col>
            </Row>
        </>
    )
}

export default UserGroups