import {
  ANY_PAGE_COMPONENT_NAME,
  COUNT,
  PAGE_LOAD_COUNT,
} from 'src/constants/MetricConstants';
import { KatalLoggerClient } from 'src/logger/KatalLoggerClient';
import { AuthenticatedUser } from 'src/models/AuthenticatedUser';


export class MetricUtils {
  static userContext: AuthenticatedUser | undefined;


  /**
   * Method to send Page Load Count Metrics
   * @param logger katalLoggerClient object
   * @param pageName name of the current page
   */
  static pageLoadCountMetrics = (pageName: string, logger: KatalLoggerClient) => {
    logger
        .logEMFMetric(
            {
              metricName: PAGE_LOAD_COUNT,
              metricValue: 1,
              dimensions: [
                {
                  'Component Name': pageName,
                },
              ],
              metricUnit: COUNT,
            },
            MetricUtils.userContext,
        )
        .then();
  };
}