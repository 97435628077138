/* eslint-disable */
import {Field} from "src/models/ProfilePermission";

/**
 * Request model for GetQueues API
 */
export interface GetQueuesRequest {
    domainId: string;
    pageSize: number;
    pageToken?: string;
    queueId?: string;
    queueNameWildcardKeyword?: string;
    userId?: string;
}

/**
 * Request model for GetUsers API
 */
export interface GetUsersRequest {
    domainId: string;
    pageSize: number;
    pageToken?: string;
    queueId?: string;
    userGroupId?: string;
    isAdmin?: boolean;
}

/**
 * Request model for CreateOrUpdateQueues API
 */
export interface CreateOrUpdateQueuesRequest {
    domainId: string;
    queueId?: string;
    name?: string;
    description?: string;
    additionalAttributes?: string;
    lastUpdatedBy: string;
    createdBy?: string;
    userOperation?: QueueUserOperation
}

export interface CreateOrUpdateUserGroupsRequest {
    domainId: string;
    userGroupId?: string;
    name?: string;
    description?: string;
    additionalAttributes?: string;
    lastUpdatedBy: string;
    createdBy?: string;
    userGroupOperation?: UserGroupOperation
}

/**
 * Request model for CreateOrUpdateUsers API
 */
export interface CreateOrUpdateUsersRequest {
    domainId: string;
    userId?: string;
    userName?: string;
    employeeId?: string;
    profileId?: string;
    name?: string;
    supportCountries?: string[];
    primaryDomain?: string;
    isHrForHr?: boolean;
    lastUpdatedBy: string;
    lastUpdatedDate?: number;
    createdBy?: string;
    createdDate?: number;
}

/**
 * Request model for GetProfilePermissions API
 */
export interface GetProfilePermissionsRequest {
    domainId: string;
    pageSize: number;
    profileId: string;
    entityType: string;
    pageToken?: string;
}

export interface CreateOrUpdateQueuesFormData {
    queueId?: string;
    name?: string;
    description?: string;
    additionalAttributes?: string;
    userOperation?: QueueUserOperation;
}

export interface CreateOrUpdateQueuesToUserMapFormData {
    queueId: string;
    name: string;
    description?: string;
    additionalAttributes?: string;
    userOperation: QueueUserOperation;
    employeeIds: string;
}

export interface CreateOrUpdateGroupToUserMapFormData {
    userGroupId: string;
    name: string;
    userOperation: UserGroupOperation;
    employeeIds: string;
}

export interface CreateOrUpdateUserGroupsFormData {
    name?: string;
    description?: string;
    userGroupId?: string;
    additionalAttributes?: string;
    userOperation?: UserGroupOperation;
}

export interface CreateOrUpdateUsersFormData {
    name?: string;
    userName?: string;
    userId?: string;
    employeeId?: string;
    profileId?: string;
    supportCountries?: string;
    isHrForHr?: boolean;
    primaryDomain?: string;
}

export interface EmployeeDetails {
    fullName: string;
    username: string;
    employeeId: string;
}

/**
 * Request model for CreateOrUpdateProfilePermissions API
 */
export interface CreateOrUpdateProfilePermissionsRequest {
    domainId: string;
    name?: string;
    fields?: Field[];
    entityType?: string;
    profileId?: string;
    lastUpdatedBy: string;
    lastUpdatedDate?: number;
    createdBy?: string;
    createdDate?: number;
}

export interface QueueUserOperation {
    type: QueueUserOperationType;
    userIds: Array<string>;
}

export enum QueueUserOperationType {
    ADD = 'ADD',
    REMOVE = 'REMOVE'
}

export interface UserGroupOperation {
    type: UserGroupOperationType;
    userIds?: Array<string>;
    employeeIds?: Array<string>;
}

export enum UserGroupOperationType {
    ADD = 'ADD',
    REMOVE = 'REMOVE'
}

/**
 * Request model for GetProfiles API
 */
export interface GetProfilesRequest {
    domainId: string;
    pageSize: number;
    pageToken?: string;
    profileId?: string;
}

/**
 * Request model for CreateOrUpdateProfiles API
 */
export interface CreateOrUpdateProfilesRequest {
    domainId: string;
    name?: string;
    title?: string;
    profileId?: string;
    description?: string;
    lastUpdatedBy: string;
    createdBy?: string;
}

export interface CreateOrUpdateProfilesFormData {
    name?: string;
    description?: string;
    title?: string;
    profileId?: string;
}

export interface CreateOrUpdateProfilePermissionsFormData {
    fields?: Field[];
    entityType?: string;
    profileId?: string;
}

export interface GetUserDetailsRequest {
    domainId: string;
    employeeId?: string;
    userId?: string;
    isAdmin?: boolean;
}

export interface SearchEmployeeRequest {
    searchTerm: string;
    size: number;
}

export interface GetUserGroupsRequest {
    domainId: string;
    pageSize: number;
    userId?: string;
    userGroupId?: string;
}
