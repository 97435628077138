import { useState } from 'react';
import { ExceptionDetail } from 'src/models/ExceptionDetail';

export interface AsyncStateHandlers<T = any, E = ExceptionDetail> {
    data?: T;
    setData: (data: T) => void;
    isLoading: boolean;
    setIsLoading: (data: boolean) => void;
    error?: E;
    setError: (data: E) => void;
    setAsyncSuccess: (data: T) => void;
    initiateAsyncState: (data?: T, error?: E) => void;
    setAsyncFailure: (error: E) => void;
}

export const DEFAULT_LATEST_REQUEST_ID = '';

export const useAsyncState = <T = any, E = ExceptionDetail>(
    initialIsLoading?: boolean,
    initialData?: T,
): AsyncStateHandlers<T, E> => {
    const [data, setData] = useState<T | undefined>(initialData);
    const [error, setError] = useState<E>();
    const [isLoading, setIsLoading] = useState<boolean>(initialIsLoading !== false);

    const initiateAsyncState = (data?: T, error?: E) => {
        setData(data);
        setError(error);
        setIsLoading(true);
    };

    const setAsyncSuccess = (data: T) => {
        setData(data);
        setIsLoading(false);
    };

    const setAsyncFailure = (error: E) => {
        setError(error);
        setIsLoading(false);
    };

    return {
        data,
        setData,
        error,
        setError,
        isLoading,
        setIsLoading,
        setAsyncSuccess,
        initiateAsyncState,
        setAsyncFailure
    };
};