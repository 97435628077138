import { Cookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie/cjs/types';
import {
    USER_SESSION_COOKIE_EXPIRY_TIME,
    USER_SESSION_ID,
} from 'src/constants/AccessConstants';
import { v4 as uuid } from 'uuid';

export class Session {
    //here time is in milliseconds
    static COOKIE_TTL = 7200000;

    static cookies = new Cookies();
    static cookieStorageOptions: CookieSetOptions = {
        path: '/',
        secure: true,
        sameSite: 'strict',
        domain: window.location.hostname,
        expires: new Date(Date.now() + Session.COOKIE_TTL),
    };

    /**
     * Method to return current session id
     */
    static getCurrentSessionId() {
        return Session.cookies.get(USER_SESSION_ID);
    }

    /**
     * Method to create sessionId & store it in cookies
     * This method also set refreshing of session after a fixed amount of time
     */
    static createSessionIdAndStoreInCookies() {
        const sessionId = uuid().toString();
        Session.cookies.set(USER_SESSION_ID, sessionId, Session.cookieStorageOptions);
        Session.cookies.set(
            USER_SESSION_COOKIE_EXPIRY_TIME,
            Session.cookieStorageOptions.expires,
            Session.cookieStorageOptions,
        );
        setTimeout(() => {
            Session.createSessionIdAndStoreInCookies();
        }, Session.COOKIE_TTL);
    }

    /**
     * Method to refresh session if user has joined in between their previous session
     * We calculate the difference between their session expiry & current time
     * and set session refresh after that time
     */
    static setSessionRefresh() {
        setTimeout(() => {
            Session.createSessionIdAndStoreInCookies();
        }, new Date(Session.cookies.get(USER_SESSION_COOKIE_EXPIRY_TIME)).getTime() - new Date().getTime());
    }
}

export default Session;