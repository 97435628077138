import React, {useContext} from "react";
import {PageHeader, PageHeaderLink} from "@amzn/stencil-react-components/page";
import {Row, Spacer} from "@amzn/stencil-react-components/layout";
import {Avatar, AvatarColorScheme} from "@amzn/stencil-react-components/avatar";
import {UserContext} from "src/components/Authentication/Authentication";
import {PHONE_TOOL} from "src/constants/DisplayConstants";

const NavigationBar = () => {
    const authenticatedUser = useContext(UserContext).authenticatedUser;
    const fullName = authenticatedUser.firstName + ' ' + authenticatedUser.lastName;
    return (
        <PageHeader>
            <nav style={{ display: 'flex', flex: 1 }}>
                <Row height="100%" width="100%">
                    <PageHeaderLink href="/">Admin Console</PageHeaderLink>
                    <Spacer flex={1} />
                    <PageHeaderLink
                        key={'user-avatar'}
                        hasHover={false}
                        href={PHONE_TOOL + `${authenticatedUser.alias}`}
                        target="_blank"
                    >
                        <Avatar
                            isUsernameClickable={false}
                            colorScheme={AvatarColorScheme.Inverted}
                            fullName={fullName}
                            username={authenticatedUser.alias}
                            showUsername={false}
                        />
                    </PageHeaderLink>
                </Row>
            </nav>
        </PageHeader>
    )
}

export default NavigationBar