/* eslint-disable */
import {GetUsersRequest} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import {DEFAULT_REQUEST_PAGE_SIZE, GLOBAL} from "src/constants/DisplayConstants";
import AsyncRequestHandler from "src/utils/HttpUtils/HttpHandler";
import {CaseAuthorityService} from "src/service/CaseAuthorityService";
import {GetUsersResponse} from "src/models/ServiceResponses/CaseAuthorityServiceResponses";
import {ExceptionDetail} from "src/models/ExceptionDetail";
import { logger } from "src/logger/KatalLoggerClient";
const getUsers = async (domainId: string, queueId?: string, userGroupId?: string) => {
    try {
        let getUsersRequest;
        if (domainId === GLOBAL) {
            getUsersRequest = buildGetUsersRequest(domainId, DEFAULT_REQUEST_PAGE_SIZE, queueId, userGroupId, true);
        } else {
            getUsersRequest = buildGetUsersRequest(domainId, DEFAULT_REQUEST_PAGE_SIZE, queueId, userGroupId);
        }
        const response = await AsyncRequestHandler(CaseAuthorityService.getUsers(getUsersRequest));
        return buildGetUsersResponse(response);
    } catch (error) {
        logger.error('Fetch Users call failed', error.message).then();
        throw buildGetUsersException(error);
    }
};
const buildGetUsersRequest = (domainId: string, pageSize: number, queueId?: string, userGroupId?:string, isAdmin?:boolean, pageToken?: string): GetUsersRequest =>  {
    if (pageToken) {
        return {domainId: domainId, queueId: queueId, pageSize: pageSize, pageToken: pageToken, userGroupId:userGroupId, isAdmin: isAdmin}
    }
    return {domainId: domainId, queueId: queueId, pageSize: pageSize, userGroupId: userGroupId, isAdmin: isAdmin}
}

const buildGetUsersResponse = (response): GetUsersResponse => {
    return {
        ...response,
    } as GetUsersResponse;
};

const buildGetUsersException = (error): ExceptionDetail => {
    return {
        errorCode: error.response.data.errorCode,
        errorStatus: error.response.status,
        errorMessage: error.message,
        retryable: error.retryable,
    } as ExceptionDetail;
};


const GetUsersBuilder = {
    getUsers: getUsers,
};

export default GetUsersBuilder;