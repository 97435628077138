/* eslint-disable */
import React, {useContext, useState} from "react";
import {UserContext} from "src/components/Authentication/Authentication";
import {Modal} from "@amzn/stencil-react-components/modal";
import {Button, ButtonVariant} from "@amzn/stencil-react-components/button";
import {FormWrapper, Input} from "@amzn/stencil-react-components/form";
import {EditEntityModalInputElements} from "src/configurations/InputElementConfigs";
import {ENTITY_TYPE} from "src/constants/enums";
import {H1, Label} from "@amzn/stencil-react-components/text";
import {Col, Flex, Row} from "@amzn/stencil-react-components/layout";
import {IconMinus, IconPlus} from "@amzn/stencil-react-components/icons";
import {
    CreateOrUpdateUserGroupsFormData
} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import CreateOrUpdateUserGroups from "src/builders/CreateOrUpdateUserGroups";
import {
    buildAdditionalAttributeArray,
    buildAdditionalAttributes,
    DEFAULT_INITIAL_ATTRIBUTES
} from "src/utils/commons/AdditionalAttributesUtils";

interface EditUserGroupsModalProps {
    domainId: string,
    data: any,
    onClose?: Function,
    onTableReload?: Function,
}

const EditUserGroupsModal = ({
                             domainId,
                             data,
                             onClose,
                             onTableReload,
                         }: EditUserGroupsModalProps) =>  {

    const [isOpen, setIsOpen] = useState(true);
    const [additionalAttribute, setAdditionalAttribute] = useState(buildAdditionalAttributeArray(data.additionalAttributes));
    const [userGroupDetails, setUserGroupDetails] = React.useState(data);
    const userContext = useContext(UserContext).authenticatedUser;

    const editUserGroups = async (close) => {
        try {
            let additionalAttributeDetails = buildAdditionalAttributes(additionalAttribute);
            const userGroupsDetailsRequest : CreateOrUpdateUserGroupsFormData = {...userGroupDetails,
                additionalAttributes: JSON.stringify(additionalAttributeDetails)
            }
            const editUserGroupResponse = await CreateOrUpdateUserGroups.editUserGroup(domainId, userContext.alias, userGroupsDetailsRequest);
            if (onTableReload) {
                onTableReload(domainId);
            }
        } catch (error) {
            console.error(error.message);
        }
        close();
    };

    // additional attributes methods.
    const handleChangeInput = (index, event) => {
        additionalAttribute[index][event.target.name] = event.target.value;
        setAdditionalAttribute([...additionalAttribute]);
    };

    const handleAddFields = () => {
        setAdditionalAttribute([...additionalAttribute, {...DEFAULT_INITIAL_ATTRIBUTES}])
    };

    const handleRemoveFields = (index) => {
        const values = [...additionalAttribute];
        values.splice(index, 1);
        if (values.length === 0) {
            setAdditionalAttribute([{...DEFAULT_INITIAL_ATTRIBUTES}]);
        } else {
            setAdditionalAttribute(values);
        }
    };

    const close = () => {
        if (onClose) {
            onClose();
        }
        setIsOpen(false);
    };

    return (
        <Modal isOpen={isOpen} close={close}>
            <Col maxWidth="90vw"
                 backgroundColor="neutral0"
                 gridGap="S500"
                 padding="S500"
                 alignItems="flex-start"
                 margin="10px">
                <H1 fontWeight={400} fontSize={'35px'}>Edit UserGroup </H1>
                <FormWrapper width={600}>
                    {EditEntityModalInputElements[ENTITY_TYPE.USER_GROUPS].map((entity) => (
                        <>
                            <Label>{entity.labelText}</Label>
                            <Input
                                defaultValue={userGroupDetails[entity.id]}
                                id={entity.id}
                                onChange={(event) => {
                                    userGroupDetails[entity.id] = event.target.value;
                                }}
                                disabled={entity.disabled}
                            />
                        </>
                    ))}
                    <Label>Additional Attributes</Label>
                    {additionalAttribute.map((attribute, index) => (
                        <Flex
                            key={index}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                        >
                            <Flex flexDirection={"row"}>
                                <Input
                                    name="key"
                                    value={attribute.key}
                                    onChange={(event) =>
                                        handleChangeInput(index, event)
                                    }
                                />
                                <Input
                                    name="value"
                                    value={attribute.value}
                                    onChange={(event) =>
                                        handleChangeInput(index, event)
                                    }
                                />
                            </Flex>
                            <Flex flexDirection={"row"}>
                                <Button
                                    icon={<IconMinus />}
                                    onClick={() => handleRemoveFields(index)}
                                ></Button>
                                <Button
                                    icon={<IconPlus />}
                                    onClick={handleAddFields}
                                ></Button>
                            </Flex>
                        </Flex>
                    ))}
                </FormWrapper>
                <Row
                    gridGap="S100"
                    alignSelf="flex-end">
                    <Button onClick={() => editUserGroups(close)} variant={ButtonVariant.Primary}>
                        Update
                    </Button>
                </Row>
            </Col>
        </Modal>
    );
}

export default EditUserGroupsModal;