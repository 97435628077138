import KatalLogger, { Level, LoggerConfig } from '@amzn/katal-logger';
import { PXTCMSAdminLoggerClient } from 'src/logger/PXTCMSAdminLoggerClient';
import { DIMENSION, EMFMetric, EMFMetricPayload } from 'src/models/Metrics';
import { API_ENDPOINTS } from 'src/constants/Urls';
import { Session } from 'src/components/Session/Session';
import { AuthenticatedUser } from 'src/models/AuthenticatedUser';
import { AuthUtils } from 'src/utils/AuthUtils';
/**
 * LoggingClient which emits logs to cloudwatch.
 * TODO: Add interfaces once hawkeye schema is decided
 *
 *   Example usage
 *   const loggerClient = new KatalLoggerClient();
 *   loggerClient.info(message).then();
 */

const LOCAL_API_GATEWAY_ENDPOINT: string = 'https://g38vqlsydk.execute-api.us-west-2.amazonaws.com/prod/v1/log';
const NAMESPACE = 'pxtcmsadminwebsite';
const LOGGER_ERROR_MESSAGE = 'Error occurred while sending metric';

export class KatalLoggerClient implements PXTCMSAdminLoggerClient {
    private logger: KatalLogger | null = null;
    private currentIdToken: string = '';

    private initKatalLoggerInstance(katalLoggerConfig: LoggerConfig): KatalLogger {
        return new KatalLogger(katalLoggerConfig);
    }

    /**
     * Checks if id token is refreshed,
     * If yes, creates a new instance of KatalLogger internally and returns for use
     * else returns existing KatalLogger instance
     * */
    private async getKatalLogger(): Promise<KatalLogger> {
        const katalLoggerConfig: LoggerConfig = {
            url: process.env.NODE_ENV === 'production' ? API_ENDPOINTS.KATAL_LOGGER : LOCAL_API_GATEWAY_ENDPOINT,
            logThreshold: Level.INFO,
            maxLogLineSize: 10000,
            headers: {
                'Accept-Language': '*',
                Authorization: '',
            },
        };

        const newIdToken: string = await AuthUtils.getCognitoIdToken();
        if (this.currentIdToken !== newIdToken || !this.logger) {
            this.currentIdToken = newIdToken;
            katalLoggerConfig.headers = {
                ...katalLoggerConfig.headers,
                Authorization: newIdToken,
            };
            this.logger = this.initKatalLoggerInstance(katalLoggerConfig);
        }
        return this.logger;
    }

    async info(
        message: string,
        context?: Record<string, unknown>,
        includeStandardContext: boolean = true,
    ): Promise<void> {
        try {
            const loggingContext = includeStandardContext ? this.applyStandardContext(context) : context;
            await (await this.getKatalLogger()).info(message, loggingContext);
        } catch (error) {
            console.log(LOGGER_ERROR_MESSAGE);
        }
    }

    async error(
        message: string,
        error: Error,
        context?: Record<string, unknown>,
        includeStandardContext: boolean = true,
    ): Promise<void> {
        try {
            const loggingContext = includeStandardContext ? this.applyStandardContext({ error, context }) : context;
            await (await this.getKatalLogger()).error(message, loggingContext);
        } catch (error) {
            console.log(LOGGER_ERROR_MESSAGE);
        }
    }

    /**
     *  metricName, metricValue, metricUnit and dimensions are required for the emission of metrics,
     *  this is required as part of the input.
     * @param metricPayload EMFMetriPayload
     */

    async logEMFMetric(metricPayload: EMFMetricPayload, userContext: AuthenticatedUser | undefined): Promise<void> {
        try {
            const emfLog = this.createEmfLog(metricPayload);
            await (await this.getKatalLogger()).info('EMF Metric', { emfLog: { ...emfLog, ...userContext } });
        } catch (error) {
            console.log(LOGGER_ERROR_MESSAGE);
        }
    }

    /**
     * Used to add standard info that we want in every log
     * @param additionalContext
     */

    private applyStandardContext(additionalContext?: Record<string, unknown>): Record<string, unknown> {
        return {
            sessionId: Session.getCurrentSessionId(),
            ...additionalContext,
        };
    }

    private createEmfLog = ({ metricName, metricValue, metricUnit, dimensions, namespace = NAMESPACE }): EMFMetric => {
        const allDimensions = this.getDimensionStructure(dimensions);
        const emfLog = {
            _aws: {
                Timestamp: Date.now(),
                CloudWatchMetrics: [
                    {
                        Namespace: namespace,
                        Dimensions: allDimensions.dimensionNames,
                        Metrics: [
                            {
                                Name: metricName,
                                Unit: metricUnit,
                            },
                        ],
                    },
                ],
            },
            ...allDimensions.dimensionValues,
            [metricName]: metricValue,
        };
        return emfLog;
    };

    private getDimensionStructure = (dimension: ReadonlyArray<DIMENSION>) => {
        const dimensionNames: string[][] = [];
        const dimensionValues = {};
        dimension.forEach((group) => {
            dimensionNames.push(Object.keys(group));
            Object.keys(group).forEach((record) => {
                dimensionValues[record] = group[record];
            });
        });
        return {
            dimensionNames,
            dimensionValues,
        };
    };
}

export const logger = new KatalLoggerClient();