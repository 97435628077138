import {
  PageHeaderDropdown,
  PageHeaderDropdownButton,
} from "@amzn/stencil-react-components/page";
import React, {ReactNode} from "react";
import {DEFAULT_DROPDOWN_NAME, EXPORT_DATA_IN_CSV} from "src/constants/DisplayConstants";
import {CommonUtils, ExportCSVProps} from "src/utils/commons/CommonUtils";

export interface ListOfDropdownActions {
    executeAction?: Function,
    actionName: string
}
export interface DropdownActionsProps extends ExportCSVProps {
    dropDownName?: string,
    listOfActions: ListOfDropdownActions[]
}

export const DropDownActions = ({listOfActions, dropDownName, fileName, fileData, csvExportEnabled}: DropdownActionsProps) => {
    const dropDownItems: ReactNode[] = listOfActions.map((action) =>
        <PageHeaderDropdownButton onClick={() => action.executeAction? action.executeAction() : null}>
            {action.actionName}
        </PageHeaderDropdownButton>);
    if(csvExportEnabled) {
        dropDownItems.push(<PageHeaderDropdownButton onClick={(e) => {
            CommonUtils.exportDataInCSV({fileName, fileData});
        }}>{EXPORT_DATA_IN_CSV}</PageHeaderDropdownButton>);
    }
  return (
      <>
        <PageHeaderDropdown items={ dropDownItems }> {dropDownName?? DEFAULT_DROPDOWN_NAME} </PageHeaderDropdown>
      </>
  );
};