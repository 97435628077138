import React, { useState } from "react";
import { SearchFields } from "src/components/SearchFields";
import { Input, Select } from "@amzn/stencil-react-components/form";
import { IconSearch, IconSize } from "@amzn/stencil-react-components/icons";
const SearchBar = ({ entityName, searchByFilter }: { entityName: string, searchByFilter: Function | undefined }) =>  {
    const [value, setValue] = useState(SearchFields[entityName][0]);
    const [content, setContent] = useState<string>('');
    const [warning, setWarning] = useState<boolean>(false);
    const handleKeywordChange: React.ChangeEventHandler<HTMLInputElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
        setContent(e.target.value);
        if (e.target.value === '') {
            setWarning(false);
        }
    };
    const doKeywordSearch = e => {
        if (e.key === 'Enter' && searchByFilter) {
            searchByFilter(value, content);
        }
    };

    return (
        <>
            <Select
                id="some-select"
                labelId="some-select-label"
                width={"30%"}
                placeholder={"Search Criteria"}
                options={SearchFields[entityName]}
                defaultValue={SearchFields[entityName][0]}
                onChange={setValue}
            />
            <Input
                id='search-field'
                name='Input field'
                width={"50%"}
                insetElementLeading={{
                    element: <IconSearch margin={{ bottom: 14 }}
                                         title='Search Keyword'
                                         aria-hidden={true}
                                         size = {IconSize.Medium}
                                         color = {"primary70"} />,
                }}
                onChange={handleKeywordChange}
                onKeyDown={doKeywordSearch}
                value={content}
                data-testid='keyword-search-input'
            />
        </>
    )
};
export default SearchBar;
