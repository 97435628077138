import {
    CreateOrUpdateGroupToUserMapFormData,
    CreateOrUpdateUserGroupsFormData,
    UserGroupOperationType
} from "src/models/ServiceRequests/CaseAuthorityServiceRequests";
import {BulkFileUploadExpectedHeaders} from "src/configurations/InputElementConfigs";
import {ENTITY_TYPE} from "src/constants/enums";
import {FileUtils} from "src/utils/FileUtils";
import Papa from "papaparse";
import {BulkExceptionDetail} from "src/models/ExceptionDetail";
import {
    CreateOrUpdateUserGroupsResponse
} from "src/models/ServiceResponses/CaseAuthorityServiceResponses";
import CreateOrUpdateUserGroupsBuilder from "src/builders/CreateOrUpdateUserGroups";
import {fetchUserIdsFromEmployeeIds} from "src/pages/CaseQueues/QueueUpdateProcessingUtils";
import {EMPLOYEE_ID_SEPARATOR} from "src/constants/DisplayConstants";
import CreateOrUpdateQueuesBuilder from "src/builders/CreateOrUpdateQueuesBuilder";

export const processBulkUserGroup = async (domainId: string, userAlias: string, userGroupList: CreateOrUpdateUserGroupsFormData[]) => {
    const successResponses: string[] = [];
    const failureResponses: BulkExceptionDetail[] = [];
    for (const userGroupDetails of userGroupList) {
        try {
            let response: CreateOrUpdateUserGroupsResponse;
            if (userGroupDetails.userGroupId) {
                response = await CreateOrUpdateUserGroupsBuilder.editUserGroup(domainId, userAlias, userGroupDetails);
            } else {
                response = await CreateOrUpdateUserGroupsBuilder.createUserGroup(domainId, userAlias, userGroupDetails);
            }
            successResponses.push(response.userGroupId);
        } catch (error) {
            failureResponses.push({
                requestDetails: userGroupDetails,
                exceptionDetail: error
            });
        }
    }
    FileUtils.downloadSuccessFailureLog('BulkCreateOrUpdateUserGroups', successResponses, failureResponses);
}

export const processBulkGroupToUserMapping = async (domainId: string, userAlias: string,
                                                    userOperationType: UserGroupOperationType,
                                                    groupDetailsList: CreateOrUpdateGroupToUserMapFormData[]) => {
    const successResponses: string[] = [];
    const failureResponses: BulkExceptionDetail[] = [];
    for (const groupDetails of groupDetailsList) {
        try {
            const employeeIds = groupDetails.employeeIds.split(EMPLOYEE_ID_SEPARATOR);
            const userIds = await fetchUserIdsFromEmployeeIds(domainId, employeeIds);
            for (const userId of userIds) {
                try {
                    groupDetails.userOperation = {type: userOperationType, userIds: [userId], employeeIds: undefined}
                    const response = await CreateOrUpdateUserGroupsBuilder.editUserGroup(domainId, userAlias, groupDetails);
                    successResponses.push(response.userGroupId)
                } catch (error) {
                    failureResponses.push({
                        requestDetails: groupDetails,
                        exceptionDetail: error
                    });
                }
            }
        } catch (error) {
            failureResponses.push({
                requestDetails: groupDetails,
                exceptionDetail: error
            });
        }
    }
    FileUtils.downloadSuccessFailureLog('BulkCreateOrUpdateUserGroups', successResponses, failureResponses);
}
