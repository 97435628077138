import { Auth } from '@aws-amplify/auth';
import { USER_AUTHORIZATION_TOKEN_KEY } from 'src/constants/AccessConstants';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

/**
 * Utility class for handling Cognito auth
 * */
export const AuthUtils = {
    /**
     * Returns the Cognito ID token. If expired, it will fetch a new one, else return the cached by AWS Amplify.
     * In  case of error, it will return the cached token from cookie.
     * @returns {Promise<string>}
     * */
    getCognitoIdToken: async (): Promise<string> =>
        await Auth.currentSession()
            .then((cognitoUserSession) => {
                const idToken = cognitoUserSession.getIdToken().getJwtToken().toString();
                if (cookies.get(USER_AUTHORIZATION_TOKEN_KEY) !== idToken) {
                    // maintain the latest token in cookie to use in fallback logic
                    cookies.set(USER_AUTHORIZATION_TOKEN_KEY, idToken);
                }
                return idToken;
            })
            .catch((err) => cookies.get(USER_AUTHORIZATION_TOKEN_KEY)), // fallback logic: get token from cookie

    /**
     * Returns the current authenticated user.
     * @returns {Promise<any>}
     * */
    currentAuthenticatedUser: () => Auth.currentAuthenticatedUser(),
};