import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { ModalContent, WithModal } from "@amzn/stencil-react-components/modal";
import React, { MouseEventHandler } from "react";

// keeping an empty CreateModal, will be removed as we will create separate modal for separate pages.
const CreateModal = () => {
  return <></>;
};

export default CreateModal;
