import React from 'react';
import { Col } from '@amzn/stencil-react-components/layout';
import { Spinner, SpinnerProps } from '@amzn/stencil-react-components/spinner';
import { ViewFullCenterFlexProps } from 'src/constants/PropsConstants';

interface ContentLoaderProps extends SpinnerProps {
    height?: string;
    width?: string;
    loadingText?: string;
}

const ContentLoader = ({ height, loadingText, width, ...otherProps }: ContentLoaderProps) => {
    return (
        <Col {...ViewFullCenterFlexProps} height={height ? height : '100%'} width={width ? width : '100%'}>
            <Spinner loadingText={loadingText} showText={!!loadingText} {...(otherProps as any)} fr={'true'} />
        </Col>
    );
};

export default ContentLoader;